import * as Yup from 'yup';
import formModels from './RecipeFormModel';
import { ObjectSchema } from 'yup';
import { ICookingStepsForm, IRecipeForm, IRecipeIngredientsForm } from '../../../recipe.interfaces';
import validatorHelpers from '../../../../helpers/validation.helpers';
import en from '../../../../../../assets/language/en.json';
import { IngredientData } from '../../../../ingredients/ingredients.interfaces';

const {
    formField: {
        name_en,
        name_de,
        cookingMethod,
        difficulty,
        restingTime,
        preparationTime,
        cookingTime,
        servings,
        shouldTimeScale,
        proRecipe,
        adminRecipe,
    },
} = formModels.recipeFormModel;

const {
    cookingStepsFormField: { countdown, master_probe, relevant_for_dashboard, cooking_step_method },
} = formModels.cookingStepsFormModel;

const recipeValidationSchema: ObjectSchema<IRecipeForm> = Yup.object()
    .defined()
    .shape({
        name_en: Yup.string().required(`${name_en.requiredErrorMsg}`).defined(),
        name_de: Yup.string().required(`${name_de.requiredErrorMsg}`).defined(),
        thumbnail_picture: Yup.mixed(),
        full_picture: Yup.mixed(),
        cooking_method_ids: Yup.array()
            .of(Yup.number().required(`${cookingMethod.requiredErrorMsg}`).defined())
            .required(cookingMethod.requiredErrorMsg),
        difficulty_en: Yup.string().required(`${difficulty.requiredErrorMsg}`).defined(),
        resting_time: Yup.number().required(`${restingTime.requiredErrorMsg}`).defined(),
        preparation_time: Yup.number().required(`${preparationTime.requiredErrorMsg}`).defined(),
        cooking_time: Yup.number().required(`${cookingTime.requiredErrorMsg}`).defined(),
        overnightTime: Yup.boolean().optional(),
        serving: Yup.number().required(`${servings.requiredErrorMsg}`).defined(),
        minimumServing: Yup.number().defined(),
        servingSteps: Yup.number().defined(),
        ambassador_id: Yup.number().nullable(true).default(null),
        grillModelIds: Yup.array().of(Yup.number().defined()).defined(),
        shouldTimeScale: Yup.boolean().required(shouldTimeScale.requiredErrorMsg),
        proRecipe: Yup.boolean().required(proRecipe.requiredErrorMsg),
        adminRecipe: Yup.boolean().required(adminRecipe.requiredErrorMsg),
        hintTitleDe: Yup.string().nullable().optional(),
        hintTitleEn: Yup.string().nullable().optional(),
        hintDescriptionDe: Yup.string().nullable().optional(),
        hintDescriptionEn: Yup.string().nullable().optional(),
        pictureAltTextEn: Yup.string(),
        pictureAltTextDe: Yup.string(),
        tutorialIds: Yup.array().of(Yup.number()).defined(),
        linkedRecipes: Yup.array(),
    });

const cookingStepsValidationSchema: ObjectSchema<ICookingStepsForm> = Yup.object()
    .defined()
    .shape({
        duration: Yup.number().nullable(true),
        relevant_for_dashboard: Yup.boolean(),
        first_zone: Yup.number().nullable(true),
        second_zone: Yup.number().nullable(true),
        third_zone: Yup.number().nullable(true),
        fourth_zone: Yup.number().nullable(true),
        master_probe: Yup.number().nullable(true),
        second_probe: Yup.number().nullable(true),
        third_probe: Yup.number().nullable(true),
        fourth_probe: Yup.number().nullable(true),
        long_description_en: Yup.string().defined(),
        long_description_de: Yup.string().defined(),
        actions: Yup.array(),
        meatProbeOrCountdown: Yup.boolean().when([master_probe.name, countdown.name], {
            is: (master_probe, duration) => validatorHelpers.isOneAtMostValidator([master_probe], duration),
            then: Yup.bool().required(en.meat_probe_or_countdown_error_msg),
            otherwise: Yup.boolean().when([master_probe.name, countdown.name, relevant_for_dashboard.name], {
                is: (master_probe, duration, relevant_for_dashboard) =>
                    relevant_for_dashboard ? validatorHelpers.isOneAtLeastValidator([master_probe], duration) : true,
                then: Yup.bool(),
                otherwise: Yup.bool().required(en.at_least_one_meat_probe_or_countdown_error_msg),
            }),
        }),
        directOrIndirect: Yup.boolean().when([relevant_for_dashboard.name, cooking_step_method.name], {
            is: (relevant, method) => relevant && !method,
            then: Yup.bool().required('A cooking step type is required'),
            otherwise: Yup.bool(),
        }),
    });

const ingredientValidationSchema: ObjectSchema<IRecipeIngredientsForm> = Yup.object()
    .defined()
    .shape({
        ingredient: Yup.object<IngredientData, IngredientData>().required(),
        metricQuantity: Yup.number().nullable(true),
        imperialQuantity: Yup.number().nullable(true),
        isMeasurementUnitsConversionOn: Yup.boolean().nullable(false).required(),
    });

const validationSchemas = {
    recipeSchema: recipeValidationSchema,
    cookingStepsSchema: cookingStepsValidationSchema,
    ingredientSchema: ingredientValidationSchema,
};

export default validationSchemas;

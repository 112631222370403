import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { IFilters, IPagination } from '../../shared/components/interfaces';
import { globalConstants } from '../../shared/constants/global.constants';
import { authHeader } from '../../shared/helpers/authHeader';
import Interceptor from '../../shared/helpers/axios.interceptor';
import { camelToSnakeCase } from '../../shared/helpers/camelToSnakeCase';
import { headers } from '../../shared/helpers/headers';
import { routeConstants } from '../../shared/routes/route.constants';
import { tutorialConstants } from '../tutorials/tutorial.constants';
import { recipeConstants } from './recipe.constants';
import { recipeEndpoints } from './recipe.endpoints';
import {
    IActionNamesResponse,
    IGrillResponse,
    IRecipe,
    IRecipeCategoryTagsForm,
    IRecipeCategoryTagsResponse,
    IRecipeCookingMethodResponse,
    IRecipeCookingSteps,
    IRecipeCookingStepsData,
    IRecipeCookingStepsToSave,
    IRecipeEquipmentForm,
    IRecipeEquipmentResponse,
    IRecipeForm,
    IRecipeGrillModelResponse,
    IRecipeIngredients,
    IRecipeIngredientsResponse,
    IRecipeProductsForm,
    IRecipeProductsResponse,
    IRecipeResponse,
    IRecipeTutorialResponse,
    LinkedRecipe,
} from './recipe.interfaces';

axios.interceptors.request.use(function (config) {
    config.headers.Authorization = localStorage.getItem('token');

    return config;
});
axios.interceptors.response.use((response) => {
    return response;
}, Interceptor(axios));

const getRecipes = async (
    paging: IPagination,
    searchText?: string,
    filters?: IFilters,
    recipeSelectionId?: number,
): Promise<IRecipeResponse | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: recipeEndpoints.GET_RECIPES.url,
        method: routeConstants.METHODS.GET,
        headers: headers.routeHeaders(),
        params: {
            page: paging.page,
            paging: paging.paging,
            size: paging.size,
            orderBy: camelToSnakeCase(paging.orderBy),
            orderDirection: paging.orderDirection,
            searchText,
            recipeSelectionId,
            filters: JSON.stringify(filters),
        },
    };

    const response = await axios(requestOptions);

    return response.data.data;
};

const getRecipeById = async (recipeId: number): Promise<IRecipe | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: recipeEndpoints.GET_RECIPE_BY_ID.url + recipeId,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data.data;
};

const addRecipe = async (recipeData: IRecipeForm): Promise<IRecipeResponse> => {
    const formData = new FormData();

    for (const key in recipeData) {
        if (!recipeData.hasOwnProperty(key)) {
            continue;
        }

        // We dont want 'null', we want ''
        if (['pictureAltTextEn', 'pictureAltTextDe'].includes(key)) {
            formData.append(`${key}`, recipeData[key]);
            continue;
        }

        formData.append(`${key}`, recipeData[key] === '' ? null : recipeData[key]);
    }

    const requestOptions: AxiosRequestConfig = {
        url: recipeEndpoints.ADD_RECIPE.url,
        method: routeConstants.METHODS.POST,
        headers: authHeader(),
        data: formData,
    };
    const response = await axios(requestOptions);

    return response.data;
};

const updateRecipe = async (recipeData: IRecipeForm, recipeId: number): Promise<IRecipeResponse> => {
    const formData = new FormData();

    for (const key in recipeData) {
        // We dont want 'null', we want ''
        if (['pictureAltTextEn', 'pictureAltTextDe'].includes(key)) {
            formData.append(`${key}`, recipeData[key]);
            continue;
        }

        if (!recipeData.hasOwnProperty(key)) {
            continue;
        }
        if (key === globalConstants.KEYS.ID) {
            continue;
        }
        formData.append(`${key}`, recipeData[key] === '' ? null : recipeData[key]);
    }

    const requestOptions: AxiosRequestConfig = {
        url: recipeEndpoints.UPDATE_RECIPE.url + recipeId,
        method: routeConstants.METHODS.PUT,
        headers: authHeader(),
        data: formData,
    };

    const response = await axios(requestOptions);

    return response.data;
};

const getRecipeCategoryTags = async (recipeId: number): Promise<IRecipeCategoryTagsResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: recipeEndpoints.GET_RECIPE_CATEGORY_TAGS.url + recipeId,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data.data;
};

const addRecipeCategoryTags = async (recipeCategoryTags: IRecipeCategoryTagsForm): Promise<AxiosResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: recipeEndpoints.ADD_RECIPE_CATEGORY_TAGS.url,
        method: routeConstants.METHODS.POST,
        headers: authHeader(),
        data: recipeCategoryTags,
    };

    const response = await axios(requestOptions);

    return response.data;
};

const updateRecipeCategoryTags = async (
    categoryTagsToSave: IRecipeCategoryTagsForm,
): Promise<IRecipeCategoryTagsResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: recipeEndpoints.UPDATE_RECIPE_CATEGORY_TAGS.url + categoryTagsToSave.recipe_id,
        method: routeConstants.METHODS.PUT,
        headers: authHeader(),
        data: categoryTagsToSave,
    };

    const response = await axios(requestOptions);

    return response.data;
};

const getRecipeIngredients = async (recipeId: number): Promise<IRecipeIngredientsResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: recipeEndpoints.GET_RECIPE_INGREDIENTS.url + recipeId,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data.data;
};

const updateRecipeIngredients = async (
    recipeId: number,
    recipeIngredientsToSave: IRecipeIngredients[],
): Promise<IRecipeIngredientsResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: recipeEndpoints.UPDATE_RECIPE_INGREDIENTS.url + recipeId,
        method: routeConstants.METHODS.PUT,
        headers: authHeader(),
        data: {
            recipeId,
            ingredients: recipeIngredientsToSave,
        },
    };

    const response = await axios(requestOptions);

    return response.data;
};

const deleteRecipeIngredient = async (recipeIngredientId: number): Promise<AxiosResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: recipeEndpoints.DELETE_RECIPE_INGREDIENT.url + recipeIngredientId,
        method: routeConstants.METHODS.DELETE,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data;
};

const addRecipeCookingSteps = async (recipeCookingSteps: IRecipeCookingSteps): Promise<AxiosResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: recipeEndpoints.ADD_RECIPE_COOKING_STEPS.url,
        method: routeConstants.METHODS.POST,
        headers: authHeader(),
        data: recipeCookingSteps,
    };

    const response = await axios(requestOptions);

    return response.data;
};

const getRecipeCookingSteps = async (recipeId: number): Promise<Array<IRecipeCookingStepsData>> => {
    const requestOptions: AxiosRequestConfig = {
        url: recipeEndpoints.GET_RECIPE_COOKING_STEPS.url + recipeId,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data.data;
};

const updateRecipeCookingSteps = async (
    recipeCookingStepsToSave: IRecipeCookingStepsToSave,
    recipeId: number,
): Promise<Array<IRecipeCookingStepsData>> => {
    const requestOptions: AxiosRequestConfig = {
        url: recipeEndpoints.UPDATE_RECIPE_COOKING_STEPS.url + recipeId,
        method: routeConstants.METHODS.PUT,
        headers: authHeader(),
        data: recipeCookingStepsToSave,
    };

    const response = await axios(requestOptions);

    return response.data;
};

const deleteRecipe = async (recipeId: number): Promise<AxiosResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: recipeEndpoints.DELETE_RECIPE.url + recipeId,
        method: routeConstants.METHODS.DELETE,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data;
};

const getRecipeCookingMethods = async (recipeId: number): Promise<IRecipeCookingMethodResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: recipeEndpoints.GET_RECIPES.url + `/${recipeId}` + recipeEndpoints.GET_RECIPE_COOKING_METHODS.url,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data.data;
};
/*
.* Recipe Equipment
*/
const getRecipeEquipment = async (recipeId: number): Promise<IRecipeEquipmentResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: recipeEndpoints.RECIPE_EQUIPMENT.url + recipeId,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };
    const response = await axios(requestOptions);

    return response.data.data;
};

const addRecipeEquipment = async (recipeEquipment: IRecipeEquipmentForm): Promise<AxiosResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: recipeEndpoints.RECIPE_EQUIPMENT.url,
        method: routeConstants.METHODS.POST,
        headers: authHeader(),
        data: recipeEquipment,
    };

    const response = await axios(requestOptions);

    return response.data;
};

const updateRecipeEquipment = async (
    recipeId: number,
    recipeEquipment: IRecipeEquipmentForm,
): Promise<IRecipeEquipmentResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: recipeEndpoints.RECIPE_EQUIPMENT.url + recipeId,
        method: routeConstants.METHODS.PUT,
        headers: authHeader(),
        data: recipeEquipment,
    };

    const response = await axios(requestOptions);

    return response.data;
};

const getRecipeProducts = async (recipeId: number): Promise<IRecipeProductsResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: recipeEndpoints.GET_RECIPE_PRODUCTS.url + `${recipeId}/products`,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data.data;
};

const addRecipeProducts = async (recipeProducts: IRecipeProductsForm): Promise<AxiosResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: recipeEndpoints.ADD_RECIPE_PRODUCTS.url,
        method: routeConstants.METHODS.POST,
        headers: authHeader(),
        data: recipeProducts,
    };

    const response = await axios(requestOptions);

    return response.data;
};

const updateRecipeProducts = async (recipeProducts: IRecipeProductsForm): Promise<IRecipeProductsResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: recipeEndpoints.UPDATE_RECIPE_PRODUCTS.url + `${recipeProducts.recipeId}/products`,
        method: routeConstants.METHODS.PUT,
        headers: authHeader(),
        data: recipeProducts,
    };

    const response = await axios(requestOptions);

    return response.data;
};

const getRecipeGrillModels = async (recipeId: number): Promise<IRecipeGrillModelResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: recipeEndpoints.GET_RECIPES.url + `/${recipeId}` + recipeConstants.GRILL_ROUTES.GRILLS,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data;
};

const getGrillModels = async (): Promise<IGrillResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: recipeEndpoints.GET_GRILLS.url,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data;
};

const getActionNames = async (): Promise<IActionNamesResponse | AxiosError> => {
    const requestOptions: AxiosRequestConfig = {
        url: recipeEndpoints.GET_ACTION_NAMES.url,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data;
};

const getRecipeTutorials = async (recipeId: number): Promise<IRecipeTutorialResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: recipeEndpoints.GET_RECIPES.url + `/${recipeId}` + tutorialConstants.TUTORIALS_ROUTES.TUTORIALS,
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
    };

    const response = await axios(requestOptions);

    return response.data;
};

const updateLinkedRecipes = async (linkedRecipes: LinkedRecipe[], recipeId: number): Promise<AxiosResponse> => {
    const requestOptions: AxiosRequestConfig = {
        url: recipeEndpoints.UPDATE_LINKED_RECIPES.url(recipeId),
        method: routeConstants.METHODS.PUT,
        headers: authHeader(),
        data: {
            linkedRecipes: linkedRecipes.map((lr) => ({
                linkedRecipeId: lr.id,
                quantityScalingFactor: lr.quantityScalingFactor,
            })),
        },
    };

    const response = await axios(requestOptions);

    return response;
};

const exportRecipesForCookbook = async (selectionId: number) => {
    const requestOptions: AxiosRequestConfig = {
        url: recipeEndpoints.EXPORT_RECIPES_FOR_COOKBOOK.url(selectionId),
        method: routeConstants.METHODS.GET,
        headers: authHeader(),
        responseType: 'blob',
    };

    const response = await axios(requestOptions);

    const contentTypes = response.headers['content-type'];
    const contentDisposition = response.headers['content-disposition'];

    const filename = contentDisposition?.split(';')?.[1]?.split('=')?.[1]?.trim() || 'recipe_cookbook_export.xlsx';

    const blob = new Blob([response.data]);

    return {
        blob,
        contentTypes,
        filename,
    };
};

export const recipeService = {
    getRecipeEquipment,
    addRecipeEquipment,
    updateRecipeEquipment,
    getRecipes,
    getRecipeById,
    addRecipe,
    updateRecipe,
    getRecipeCategoryTags,
    addRecipeCategoryTags,
    updateRecipeCategoryTags,
    getRecipeIngredients,
    updateRecipeIngredients,
    addRecipeCookingSteps,
    getRecipeCookingSteps,
    updateRecipeCookingSteps,
    deleteRecipe,
    getRecipeCookingMethods,
    getRecipeProducts,
    addRecipeProducts,
    updateRecipeProducts,
    deleteRecipeIngredient,
    getRecipeGrillModels,
    getGrillModels,
    getRecipeTutorials,
    getActionNames,
    updateLinkedRecipes,
    exportRecipesForCookbook,
};

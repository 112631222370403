import { uniqueId } from 'lodash';

const troubleshootFormModel = {
    formId: uniqueId('form-'),
    formField: {
        internalName: {
            name: 'internalName',
            label: 'Internal Name',
        },
        thumbnailDe: {
            name: 'thumbnailDe',
            label: 'Thumbnail (German)',
        },
        thumbnailEn: {
            name: 'thumbnailEn',
            label: 'Thumbnail (English)',
        },
        videoDe: {
            name: 'videoDe',
            label: 'Video (German)',
        },
        videoEn: {
            name: 'videoEn',
            label: 'Video (English)',
        },
        thumbnailAltTextDe: {
            name: 'thumbnailAltTextDe',
            label: 'Thumbnail alt text de',
        },
        thumbnailAltTextEn: {
            name: 'thumbnailAltTextEn',
            label: 'Thumbnail alt text en',
        },
    },
};

const troubleshootPrimaryStepFormModel = {
    formId: uniqueId('form-'),
    formField: {
        titleEn: {
            name: 'titleEn',
            label: 'Title (English)',
        },
        titleDe: {
            name: 'titleDe',
            label: 'Title (German)',
        },
        thumbnailDe: {
            name: 'thumbnailDe',
            label: 'Thumbnail (German)',
        },
        thumbnailEn: {
            name: 'thumbnailEn',
            label: 'Thumbnail (English)',
        },
        thumbnailAltTextDe: {
            name: 'thumbnailAltTextDe',
            label: 'Thumbnail alt text de',
        },
        thumbnailAltTextEn: {
            name: 'thumbnailAltTextEn',
            label: 'Thumbnail alt text en',
        },
    },
};

const troubleshootStepFormModel = {
    formId: uniqueId('form-'),
    formField: {
        defaultDescription: {
            name: 'defaultDescription',
            label: 'Default Description',
        },
        oldFirmwareIOS: {
            name: 'oldFirmwareIOS',
            label: 'Old Firmware iOS',
        },
        oldFirmwareAndroid: {
            name: 'oldFirmwareAndroid',
            label: 'Old Firmware Android',
        },
        semanticFirmwareIOS: {
            name: 'semanticFirmwareIOS',
            label: 'Semantic Firmware iOS',
        },
        semanticFirmwareAndroid: {
            name: 'semanticFirmwareAndroid',
            label: 'Semantic Firmware Android',
        },
    },
};

const formModels = {
    troubleshootFormModel,
    troubleshootStepFormModel,
    troubleshootPrimaryStepFormModel,
};

export default formModels;

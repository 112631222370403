import { z } from 'zod';
import { zodUtils } from '../../../../../shared/helpers/zodUtils';

const questionMaxLength = 80;
const answerMaxLength = 45;
const explanationMaxLength = 180;

const zodValidationSchema = z
    .object({
        id: z.number().optional().nullable(),
        questionEn: z.string().min(1).max(questionMaxLength),
        questionDe: z.string().min(1).max(questionMaxLength),
        correctAnswerEn: z.string().min(1).max(answerMaxLength),
        correctAnswerDe: z.string().min(1).max(answerMaxLength),
        wrongAnswerEn: z.string().min(1).max(answerMaxLength),
        wrongAnswerDe: z.string().min(1).max(answerMaxLength),
        answerExplanationEn: z.string().min(1).max(explanationMaxLength),
        answerExplanationDe: z.string().min(1).max(explanationMaxLength),
        questionImage: zodUtils.fileSchema.optional().nullable(),
        rewardPoints: z.number().min(1),
        closeIconColorHex: zodUtils.colorHexSchema,
        recipeId: z.number().optional().nullable(),
        productId: z.number().optional().nullable(),
        questionImageAltTextEn: z.string(),
        questionImageAltTextDe: z.string(),
    })
    .refine(
        (data) => {
            if (data.productId && data.recipeId) {
                return false;
            }
            return true;
        },
        {
            path: ['productId', 'recipeId'],
            message: 'Either recipeId or productId must be provided, not both.',
        },
    );

const quizRoundSchema = z.object({
    id: z.number().optional().nullable(),
    quizName: z.string().max(256).optional().nullable(),
    startDate: z.coerce.date().min(new Date()),
    hoursBetweenQuizQuestions: z.number().min(1),
    quizQuestions: z.array(z.object({ id: z.number(), orderNumber: z.number() })),
});

const validationSchemas = {
    zodValidationSchema,
    quizRoundSchema,
};

export default validationSchemas;

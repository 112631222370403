const filter = {
    name_en: '',
    name_de: '',
    picture: '',
    pictureAltTextEn: '',
    pictureAltTextDe: '',
};

export const formInitialValues = {
    filter,
};

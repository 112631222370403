import {
    Button,
    Card,
    CardActionArea,
    CardActions,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    InputAdornment,
    Paper,
    TextField,
    Typography,
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import clsx from 'clsx';
import { Form, Formik, FormikProps } from 'formik';
import React, { PropsWithChildren, ReactElement, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import en from '../../../../../assets/language/en.json';
import LanguageFlag from '../../../../shared/components/generics/languageFlag/LanguageFlag';
import { globalConstants } from '../../../../shared/constants/global.constants';
import { useIdParam } from '../../../../shared/helpers/custom.hooks';
import { genericHelpers } from '../../../../shared/helpers/generics';
import DragAndDrop from '../../../dndComponent/DragNDrop';
import ImportFile from '../../../import/pages/import.file';
import { upsellingActions } from '../../upselling.actions';
import { productConstants } from '../../upselling.constants';
import { IProduct, IProductCategory, IProductForm, IProductFormComponent } from '../../upselling.interfaces';
import formModels from '../addOrEditProduct/formModel/form.model';
import validationSchemas from '../addOrEditProduct/formModel/validation.schema';
import { formInitialValues } from './formModel/form.initial.values';
import styles from './index.module.scss';
import AltText from '../../../../shared/components/generics/AltText/AltText';

type Props = IProductFormComponent & typeof formModels.productFormModel;

const ProductForm = (props: PropsWithChildren<Props>): ReactElement => {
    const componentIsMounted = useRef<boolean>(true);
    const {
        history: { location },
    } = props;
    const id = useIdParam();
    const {
        formField: {
            titleEn,
            titleDe,
            picture,
            randomProduct,
            milieuPicture,
            shopifyDeAtProductId,
            shopifyUsProductId,
            shopifyDeAtVariantId,
            shopifyUsVariantId,
            pictureAltTextEn,
            pictureAltTextDe,
            milieuPictureAltTextEn,
            milieuPictureAltTextDe,
        },
    } = formModels.productFormModel;

    const hiddenHeaderPictureInput = React.useRef(null);
    const hiddenHeaderMilieuPictureInput = React.useRef(null);

    const [updatingProduct, setUpdatingProduct] = useState<any>(null);
    let formInitials = updatingProduct ? updatingProduct : formInitialValues.product;
    const [selectedCategoryIds, setSelectedCategoryIds] = useState<number[]>([]);
    const [availableCategories, setAvailableCategories] = useState<IProductCategory[]>([]);

    useEffect(() => {
        const init = async () => {
            let productResponse: IProduct = null;
            if (id) {
                productResponse = await props.getProductById(id);
                setUpdatingProduct(productResponse);
            }
            const productCategories = (await props.getProductCategories()).products;
            const alreadySelectedCategories = productResponse ? productResponse.categories.map((c) => c.id) : [];
            setAvailableCategories(
                productCategories.filter((category) => !alreadySelectedCategories.includes(category.id)),
            );
        };
        init();
    }, []);

    useEffect(() => {
        if (componentIsMounted) {
            formInitials = updatingProduct;
            componentIsMounted.current = false;
        }
    }, [updatingProduct]);

    const handleSubmit = (values: IProductForm): void => {
        values = {
            ...values,
            categories: selectedCategoryIds,
        };
        updatingProduct ? handleUpdate(values) : handleInsert(values);
    };

    const handleInsert = (values: IProductForm): void => {
        props.addProduct(values).then(() => {
            props.history.push(productConstants.PRODUCT_ROUTES.PRODUCTS);
        });
    };

    const handleUpdate = (values: IProductForm): void => {
        props.updateProduct(genericHelpers.removeIdFromObject(values), updatingProduct.id).then(() => {
            props.history.push(productConstants.PRODUCT_ROUTES.PRODUCTS);
        });
    };

    return (
        <Paper className={clsx(styles.productPaper, 'mt16')} variant={'outlined'}>
            <div className={clsx(styles.product, 'p4')}>
                <Formik
                    initialValues={formInitials}
                    onSubmit={(values: IProductForm, { setSubmitting }) => {
                        handleSubmit(values);
                        setSubmitting(false);
                    }}
                    validationSchema={validationSchemas.productValidationSchema}
                    enableReinitialize={true}
                >
                    {(props: FormikProps<IProductForm>) => (
                        <Form className={styles.addProductForm}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h3" gutterBottom className={clsx(styles.typography, 'mt8')}>
                                        {en.upselling_label}
                                    </Typography>
                                </Grid>
                                <Grid item container spacing={2} xs={9}>
                                    <Grid item xs={6}>
                                        <FormGroup className={styles.inputGroup}>
                                            <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                                <TextField
                                                    className={styles.formInput}
                                                    label={titleEn.label}
                                                    name={titleEn.name}
                                                    key={titleEn.name}
                                                    type="text"
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    value={props.values.titleEn}
                                                    variant="outlined"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <LanguageFlag country="us" />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                    {props.errors.titleEn && props.touched.titleEn
                                                        ? props.errors.titleEn
                                                        : ''}
                                                </FormHelperText>
                                            </FormControl>
                                            <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                                <TextField
                                                    className={styles.formInput}
                                                    label={shopifyUsProductId.label}
                                                    name={shopifyUsProductId.name}
                                                    key={shopifyUsProductId.name}
                                                    type="number"
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    value={props.values.shopifyUsProductId}
                                                    variant="outlined"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <LanguageFlag country="us" />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                    {props.errors.shopifyUsProductId && props.touched.shopifyUsProductId
                                                        ? props.errors.shopifyUsProductId
                                                        : ''}
                                                </FormHelperText>
                                            </FormControl>
                                            <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                                <TextField
                                                    className={styles.formInput}
                                                    label={shopifyUsVariantId.label}
                                                    name={shopifyUsVariantId.name}
                                                    key={shopifyUsVariantId.name}
                                                    type="number"
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    value={props.values.shopifyUsVariantId}
                                                    variant="outlined"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <LanguageFlag country="us" />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                    {props.errors.shopifyUsVariantId && props.touched.shopifyUsVariantId
                                                        ? props.errors.shopifyUsVariantId
                                                        : ''}
                                                </FormHelperText>
                                            </FormControl>
                                            <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                                <FormControlLabel
                                                    name={randomProduct.name}
                                                    value={props.values.randomProduct}
                                                    control={<Checkbox color="primary" onChange={props.handleChange} />}
                                                    label={randomProduct.label}
                                                    labelPlacement="end"
                                                    onChange={props.handleChange}
                                                    checked={props.values.randomProduct}
                                                />
                                            </FormControl>
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormGroup className={styles.inputGroup}>
                                            <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                                <TextField
                                                    className={styles.formInput}
                                                    label={titleDe.label}
                                                    name={titleDe.name}
                                                    key={titleDe.name}
                                                    type="text"
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    value={props.values.titleDe}
                                                    variant="outlined"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <LanguageFlag country="de" />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                    {props.errors.titleDe && props.touched.titleDe
                                                        ? props.errors.titleDe
                                                        : ''}
                                                </FormHelperText>
                                            </FormControl>
                                            <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                                <TextField
                                                    className={styles.formInput}
                                                    label={shopifyDeAtProductId.label}
                                                    name={shopifyDeAtProductId.name}
                                                    key={shopifyDeAtProductId.name}
                                                    type="number"
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    value={props.values.shopifyDeAtProductId}
                                                    variant="outlined"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <LanguageFlag country="de" />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                    {props.errors.shopifyDeAtProductId &&
                                                    props.touched.shopifyDeAtProductId
                                                        ? props.errors.shopifyDeAtProductId
                                                        : ''}
                                                </FormHelperText>
                                            </FormControl>
                                            <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                                <TextField
                                                    className={styles.formInput}
                                                    label={shopifyDeAtVariantId.label}
                                                    name={shopifyDeAtVariantId.name}
                                                    key={shopifyDeAtVariantId.name}
                                                    type="number"
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    value={props.values.shopifyDeAtVariantId}
                                                    variant="outlined"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <LanguageFlag country="de" />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                    {props.errors.shopifyDeAtVariantId &&
                                                    props.touched.shopifyDeAtVariantId
                                                        ? props.errors.shopifyDeAtVariantId
                                                        : ''}
                                                </FormHelperText>
                                            </FormControl>
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DragAndDrop
                                            destinationColumnName={'Selected category'}
                                            sourceColumnName={'Available categories'}
                                            items={props.values.categories}
                                            availableItems={availableCategories}
                                            endScroll={[(_x: boolean) => null, null]}
                                            handleItemsChange={setSelectedCategoryIds}
                                            nameKey="nameEn"
                                        />
                                        <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                            {props.errors.categories ? props.errors.categories : ''}
                                        </FormHelperText>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormGroup className={styles.inputGroup}>
                                        <FormControl className={clsx(styles.formControl, 'mb4')}>
                                            <Card>
                                                <CardActionArea className={styles.actionArea}>
                                                    <ImportFile
                                                        file={props.values.picture}
                                                        title={en.picture_label}
                                                        style={styles.imgCard}
                                                        remove={() => props.setFieldValue('picture', null)}
                                                    />
                                                </CardActionArea>
                                                <CardActions>
                                                    <input
                                                        accept="file/"
                                                        id="importFile"
                                                        type="file"
                                                        name={picture.name}
                                                        className={styles.fileInput}
                                                        ref={hiddenHeaderPictureInput}
                                                        onChange={(event) => {
                                                            props.setFieldValue(
                                                                picture.name,
                                                                event.currentTarget.files[
                                                                    globalConstants.KEYS.FIRST_FILE_INDEX
                                                                ],
                                                            );
                                                        }}
                                                    />
                                                    <Button
                                                        className={styles.uploadImg}
                                                        onClick={() => hiddenHeaderPictureInput.current.click()}
                                                        startIcon={<CloudUploadIcon />}
                                                    >
                                                        {en.upload_header_picture}
                                                    </Button>
                                                </CardActions>
                                            </Card>
                                        </FormControl>
                                        <AltText
                                            {...props}
                                            altTextEnFieldMeta={pictureAltTextEn}
                                            altTextDeFieldMeta={pictureAltTextDe}
                                        />
                                        <FormControl className={clsx(styles.formControl, 'mb4')}>
                                            <Card>
                                                <CardActionArea className={styles.actionArea}>
                                                    <ImportFile
                                                        file={props.values.milieuPicture}
                                                        title={en.milieu_picture_label}
                                                        style={styles.imgCard}
                                                        remove={() => props.setFieldValue(milieuPicture.name, null)}
                                                    />
                                                </CardActionArea>
                                                <CardActions>
                                                    <input
                                                        accept="file/"
                                                        id="importFile"
                                                        type="file"
                                                        name={milieuPicture.name}
                                                        className={styles.fileInput}
                                                        ref={hiddenHeaderMilieuPictureInput}
                                                        onChange={(event) => {
                                                            props.setFieldValue(
                                                                milieuPicture.name,
                                                                event.currentTarget.files[
                                                                    globalConstants.KEYS.FIRST_FILE_INDEX
                                                                ],
                                                            );
                                                        }}
                                                    />
                                                    <Button
                                                        className={styles.uploadImg}
                                                        onClick={() => hiddenHeaderMilieuPictureInput.current.click()}
                                                        startIcon={<CloudUploadIcon />}
                                                    >
                                                        {en.upload_header_picture}
                                                    </Button>
                                                </CardActions>
                                            </Card>
                                        </FormControl>
                                        <AltText
                                            {...props}
                                            altTextEnFieldMeta={milieuPictureAltTextEn}
                                            altTextDeFieldMeta={milieuPictureAltTextDe}
                                        />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={clsx(styles.bottomNavigation, 'mt12')}>
                                        <Button
                                            type="submit"
                                            className={clsx(styles.saveProduct, 'mr2', 'mt4')}
                                            disabled={props.isSubmitting || !props.isValid}
                                        >
                                            {en.save_button_label}
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </div>
        </Paper>
    );
};

const mapDispatchToProps = {
    addProduct: upsellingActions.addProduct,
    updateProduct: upsellingActions.updateProduct,
    getProductById: upsellingActions.getProductById,
    getProductCategories: upsellingActions.getProductCategories,
};

export default compose(withRouter, connect(null, mapDispatchToProps))(ProductForm);

import React, { PropsWithChildren, useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardActionArea,
    CardActions,
    FormControl,
    FormHelperText,
    Grid,
    InputAdornment,
    Paper,
    TextField,
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import clsx from 'clsx';
import { Form, Formik, FormikProps } from 'formik';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import en from '../../../../../assets/language/en.json';
import LanguageFlag from '../../../../shared/components/generics/languageFlag/LanguageFlag';
import { globalConstants } from '../../../../shared/constants/global.constants';
import { useIdParam } from '../../../../shared/helpers/custom.hooks';
import { DispatchPropsReturnType } from '../../../../shared/helpers/generics';
import { zodUtils } from '../../../../shared/helpers/zodUtils';
import { AppState } from '../../../../shared/store';
import ImportFile from '../../../import/pages/import.file';
import { troubleshotActions } from '../../troubleshoot.actions';
import { ITroubleshoot } from '../../troubleshoot.interfaces';
import { formInitialValues } from '../addOrEditTroubleshoot/formModel/form.initial.values';
import formModels from '../addOrEditTroubleshoot/formModel/troubleshoot.form.model';
import validationSchemas from '../addOrEditTroubleshoot/formModel/validation.schema';
import styles from './index.module.scss';
import ImageUpload from '../../../../shared/components/imageUpload';
import AltText from '../../../../shared/components/generics/AltText/AltText';

type OwnProps = {
    nextStep: (newEntityId?: number) => void;
} & DispatchPropsReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps>;

const TroubleshootInfo = (props: PropsWithChildren<OwnProps>): JSX.Element => {
    const { addTroubleshoot, getTroubleshoot, updateTroubleshoot, nextStep } = props;

    const id = useIdParam();

    const [updatingTroubleshoot, setUpdatingTroubleshoot] = useState<ITroubleshoot>(null);

    const thumbnailEnInput = React.useRef(null);
    const thumbnailDeInput = React.useRef(null);
    const videoEnInput = React.useRef(null);
    const videoDeInput = React.useRef(null);

    const formInitials = updatingTroubleshoot ?? formInitialValues.troubleshoot;
    const form = formModels.troubleshootFormModel.formField;
    useEffect(() => {
        if (id) {
            getTroubleshoot(id).then((quizQuestion) => {
                setUpdatingTroubleshoot(quizQuestion);
            });
        }
    }, []);

    const handleSubmit = async (values: ITroubleshoot): Promise<void> => {
        const saveResult = await (updatingTroubleshoot ? updateTroubleshoot(values, id) : addTroubleshoot(values));
        if (saveResult instanceof Error) {
            return;
        }
        nextStep(saveResult.id);
    };

    return (
        <Paper className={clsx(styles.paper, styles.troubleshootInfo, 'mt16')} variant={'outlined'}>
            <div className={clsx(styles.container, 'p4')}>
                <Formik
                    initialValues={formInitials}
                    onSubmit={async (values: ITroubleshoot, { setSubmitting }) => {
                        await handleSubmit(values);
                        setSubmitting(false);
                    }}
                    validate={zodUtils.zodFormikValidator(validationSchemas.zodValidationSchema)}
                    enableReinitialize={true}
                >
                    {(formProps: FormikProps<ITroubleshoot>) => (
                        <Form className={styles.addForm}>
                            <Grid container spacing={2}>
                                {/* Left grid container for main input form */}
                                <Grid item xs={12}>
                                    <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                        <TextField
                                            className={styles.formInput}
                                            label={form.internalName.label}
                                            name={form.internalName.name}
                                            type="text"
                                            required
                                            onChange={formProps.handleChange}
                                            onBlur={formProps.handleBlur}
                                            value={formProps.values.internalName}
                                            variant="outlined"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <LanguageFlag country="us" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                            {formProps.errors.internalName && formProps.touched.internalName
                                                ? formProps.errors.internalName
                                                : ''}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl className={clsx(styles.formControl, 'mb4')}>
                                        <ImageUpload
                                            fileValue={formProps.values.thumbnailEn as string}
                                            label={form.thumbnailEn.label}
                                            onChange={(file) => {
                                                formProps.setFieldValue(form.thumbnailEn.name, file);
                                            }}
                                            inputFieldName={form.thumbnailEn.name}
                                            key={form.thumbnailEn.name}
                                        />
                                        <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                            {formProps.errors.thumbnailEn && formProps.touched.thumbnailEn
                                                ? formProps.errors.thumbnailEn
                                                : ''}
                                        </FormHelperText>
                                    </FormControl>
                                    <FormControl className={clsx(styles.formControl, 'mb4')}>
                                        <ImageUpload
                                            fileValue={formProps.values.videoEn as string}
                                            label={form.videoEn.label}
                                            onChange={(file) => {
                                                formProps.setFieldValue(form.videoEn.name, file);
                                            }}
                                            inputFieldName={form.videoEn.name}
                                            key={form.videoEn.name}
                                            uploadButtonLabel={en.upload_video}
                                        />
                                        <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                            {formProps.errors.videoEn && formProps.touched.videoEn
                                                ? formProps.errors.videoEn
                                                : ''}
                                        </FormHelperText>
                                    </FormControl>
                                    <AltText
                                        {...formProps}
                                        altTextDeFieldMeta={form.thumbnailAltTextDe}
                                        altTextEnFieldMeta={form.thumbnailAltTextEn}
                                    />{' '}
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl className={clsx(styles.formControl, 'mb4')}>
                                        <ImageUpload
                                            fileValue={formProps.values.thumbnailDe as string}
                                            label={form.thumbnailDe.label}
                                            onChange={(file) => {
                                                formProps.setFieldValue(form.thumbnailDe.name, file);
                                            }}
                                            inputFieldName={form.thumbnailDe.name}
                                            key={form.thumbnailDe.name}
                                        />
                                        <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                            {formProps.errors.thumbnailDe && formProps.touched.thumbnailDe
                                                ? formProps.errors.thumbnailDe
                                                : ''}
                                        </FormHelperText>
                                    </FormControl>
                                    <FormControl className={clsx(styles.formControl, 'mb4')}>
                                        <ImageUpload
                                            fileValue={formProps.values.videoDe as string}
                                            label={form.videoDe.label}
                                            onChange={(file) => {
                                                formProps.setFieldValue(form.videoDe.name, file);
                                            }}
                                            inputFieldName={form.videoDe.name}
                                            key={form.videoDe.name}
                                            uploadButtonLabel={en.upload_video}
                                        />
                                        <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                            {formProps.errors.videoDe && formProps.touched.videoDe
                                                ? formProps.errors.videoDe
                                                : ''}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={clsx(styles.bottomNavigation, 'mt12')}>
                                        {(() => {
                                            const errorKeys = Object.keys(formProps.errors);
                                            if (errorKeys?.length > 0 && errorKeys?.length <= 3) {
                                                return (
                                                    <FormHelperText className={clsx(styles.error_span, 'mt2', 'mr4')}>
                                                        {errorKeys.map((key) => {
                                                            return (
                                                                <p
                                                                    key={form[`${key}.label`] || key}
                                                                >{`${key}: ${formProps.errors[key]}`}</p>
                                                            );
                                                        })}
                                                    </FormHelperText>
                                                );
                                            }
                                            return null;
                                        })()}
                                        <Button
                                            type="submit"
                                            className={clsx(styles.redButton, 'mr2', 'mt4')}
                                            disabled={formProps.isSubmitting || !formProps.isValid || !formProps.dirty}
                                        >
                                            {en.save_button_label}
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </div>
        </Paper>
    );
};

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = {
    getTroubleshoot: troubleshotActions.getTroubleshoot,
    addTroubleshoot: troubleshotActions.addTroubleshoot,
    updateTroubleshoot: troubleshotActions.updateTroubleshoot,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(TroubleshootInfo);

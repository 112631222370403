import en from '../../../../../../assets/language/en.json';
import { uniqueId } from 'lodash';

const promotionCampaignFormModel = {
    formId: uniqueId('form-'),
    formField: {
        titleEn: {
            name: 'titleEn',
            label: 'Headline',
            requiredErrorMsg: en.name_required_error_msg,
        },
        titleDe: {
            name: 'titleDe',
            label: 'Headline',
            requiredErrorMsg: en.name_required_error_msg,
        },
        campaignName: {
            name: 'campaignName',
            label: 'Campaign name (internal)',
            requiredErrorMsg: en.name_required_error_msg,
        },
        startDate: {
            name: 'startDate',
            label: 'Start Date',
            requiredErrorMsg: en.name_required_error_msg,
        },
        endDate: {
            name: 'endDate',
            label: 'End Date',
            requiredErrorMsg: en.name_required_error_msg,
        },
        pictureEn: {
            name: 'pictureEn',
            label: 'Banner image ENG',
        },
        pictureDe: {
            name: 'pictureDe',
            label: 'Banner image DE',
        },
        videoEn: {
            name: 'videoEn',
            label: 'Banner video ENG',
        },
        videoDe: {
            name: 'videoDe',
            label: 'Banner video DE',
        },
        regions: {
            name: 'regions',
            label: 'Regions',
            requiredErrorMsg: en.name_required_error_msg,
        },
        contentOrientation: {
            name: 'contentOrientation',
            label: 'Content side',
            requiredErrorMsg: en.name_required_error_msg,
        },
        display: {
            name: 'display',
            label: 'Display',
            requiredErrorMsg: en.name_required_error_msg,
        },
        textColorHex: {
            name: 'textColorHex',
            label: 'Headline color',
            requiredErrorMsg: en.name_required_error_msg,
        },
        buttonColorHex: {
            name: 'buttonColorHex',
            label: 'Button text color',
            requiredErrorMsg: en.name_required_error_msg,
        },
        closeIconColorHex: {
            name: 'closeIconColorHex',
            label: 'Close icon color',
            requiredErrorMsg: en.name_required_error_msg,
        },
        descriptionColorHex: {
            name: 'descriptionColorHex',
            label: 'Subheadline color',
            requiredErrorMsg: en.name_required_error_msg,
        },
        buttonTitleEn: {
            name: 'buttonTitleEn',
            label: 'Button text',
            requiredErrorMsg: en.name_required_error_msg,
        },
        buttonTitleDe: {
            name: 'buttonTitleDe',
            label: 'Button text',
            requiredErrorMsg: en.name_required_error_msg,
        },
        redirectUrlEn: {
            name: 'redirectUrlEn',
            label: 'Button link',
            requiredErrorMsg: en.name_required_error_msg,
        },
        redirectUrlDe: {
            name: 'redirectUrlDe',
            label: 'Button link',
            requiredErrorMsg: en.name_required_error_msg,
        },
        descriptionEn: {
            name: 'descriptionEn',
            label: 'Subheadline',
            requiredErrorMsg: en.name_required_error_msg,
        },
        descriptionDe: {
            name: 'descriptionDe',
            label: 'Subheadline',
            requiredErrorMsg: en.name_required_error_msg,
        },
        targetUsers: {
            name: 'targetUsers',
            label: 'Target users',
            requiredErrorMsg: en.name_required_error_msg,
        },
        segmentAudiences: {
            name: 'segmentAudiences',
            label: 'Segment audiences',
        },
        coupon: {
            name: 'coupon',
            productId: {
                name: 'coupon.productId',
                label: 'Product',
                requiredErrorMsg: en.name_required_error_msg,
            },
            discount: {
                type: {
                    name: 'coupon.discount.type',
                    label: 'Discount type',
                    requiredErrorMsg: en.name_required_error_msg,
                },
                value: {
                    name: 'coupon.discount.value',
                    label: 'Discount value',
                    requiredErrorMsg: en.name_required_error_msg,
                },
            },
            expirationDate: {
                name: 'coupon.expirationDate',
                label: 'Expiration date',
                requiredErrorMsg: en.name_required_error_msg,
            },
        },
        videoAltTextEn: {
            name: 'videoAltTextEn',
            label: 'Video alt text en',
        },
        videoAltTextDe: {
            name: 'videoAltTextDe',
            label: 'Video alt text de',
        },
        pictureAltTextEn: {
            name: 'pictureAltTextEn',
            label: 'Picture alt text en',
        },
        pictureAltTextDe: {
            name: 'pictureAltTextDe',
            label: 'Picture alt text de',
        },
    },
};

const formModels = {
    promotionCampaignFormModel,
};

export default formModels;

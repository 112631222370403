import {
    ICookingStepsForm,
    IRecipe,
    IRecipeCookingMethod,
    IRecipeCookingStepsData,
    IRecipeForm,
    IRecipeGrillModel,
    IRecipeIngredients,
    IRecipeIngredientsForm,
    IRecipeTutorial,
    IActionName,
    CookingStepTypes,
} from '../recipe/recipe.interfaces';
import { FormikProps } from 'formik';
import { recipeConstants } from '../recipe/recipe.constants';
import { alertActions } from '../../shared/actions/alert.actions';
import { richTextEditorHelpers } from '../../shared/helpers/rich.text.editor.helpers';
import { IIngredientUnit, IngredientData } from '../ingredients/ingredients.interfaces';
import { genericHelpers } from '../../shared/helpers/generics';
import formInitialValues from '../recipe/pages/addRecipe/FormModel/FormInitialValues';

const createData = (values: ICookingStepsForm, stepNumber?: number): ICookingStepsForm => {
    return {
        step_number: !genericHelpers.isNullOrUndefined(stepNumber) ? stepNumber : values.step_number,
        duration: Number(values.duration) ? values.duration : null,
        relevant_for_dashboard: values.relevant_for_dashboard,
        long_description_en: values.long_description_en,
        long_description_de: values.long_description_de,
        short_description_en: values.short_description_en,
        short_description_de: values.short_description_de,
        first_zone: Number(values.first_zone) ? values.first_zone : null,
        second_zone: Number(values.second_zone) ? values.second_zone : null,
        third_zone: Number(values.third_zone) ? values.third_zone : null,
        fourth_zone: Number(values.fourth_zone) ? values.fourth_zone : null,
        master_probe: Number(values.master_probe) ? values.master_probe : null,
        actions: values.actions,
    };
};

const recipeCookingStepsRows = (
    index: number,
    createdData: ICookingStepsForm,
    stepNumber?: number | string,
    isStepToSave?: boolean,
): ICookingStepsForm => {
    return {
        step_number: stepNumber ? stepNumber : index + 1,
        duration: Number(createdData.duration) ? createdData.duration : null,
        long_description_en: isStepToSave
            ? createdData.long_description_en
            : richTextEditorHelpers.parseRichText(createdData.long_description_en),
        long_description_de: isStepToSave
            ? createdData.long_description_de
            : richTextEditorHelpers.parseRichText(createdData.long_description_de),
        short_description_en: isStepToSave
            ? createdData.short_description_en
            : richTextEditorHelpers.parseRichText(createdData.short_description_en),
        short_description_de: isStepToSave
            ? createdData.short_description_de
            : richTextEditorHelpers.parseRichText(createdData.short_description_de),
        first_zone: Number(createdData.first_zone) ? createdData.first_zone : null,
        second_zone: Number(createdData.second_zone) ? createdData.second_zone : null,
        third_zone: Number(createdData.third_zone) ? createdData.third_zone : null,
        fourth_zone: Number(createdData.fourth_zone) ? createdData.fourth_zone : null,
        master_probe: Number(createdData.master_probe) ? createdData.master_probe : null,
        actions: createdData.actions,
        relevant_for_dashboard: createdData.relevant_for_dashboard,
    };
};

const reorderCookingStepNumber = (cookingSteps: Array<IRecipeCookingStepsData>): Array<IRecipeCookingStepsData> => {
    const cookingStepsToReorder: Array<IRecipeCookingStepsData> = [];

    cookingSteps.forEach((cookingStep, index) => {
        cookingStepsToReorder[index] = cookingStep;
        cookingStepsToReorder[index].step_number = index;
    });

    return cookingStepsToReorder;
};

const recipeMapper = (
    recipeToUpdate: IRecipe,
    recipeCookingMethods: IRecipeCookingMethod[],
    recipeGrillModels: Array<IRecipeGrillModel>,
    recipeTutorials: Array<IRecipeTutorial>,
): IRecipeForm => {
    return {
        id: recipeToUpdate.id,
        name_en: recipeToUpdate.name_en,
        name_de: recipeToUpdate.name_de,
        cooking_method_ids: recipeCookingMethods.map((value) => value.id),
        difficulty_en: recipeToUpdate.difficulty_en,
        difficulty_de: recipeToUpdate.difficulty_de,
        resting_time: recipeToUpdate.resting_time,
        preparation_time: recipeToUpdate.preparation_time,
        cooking_time: recipeToUpdate.cooking_time,
        overnightTime: recipeToUpdate.overnightTime,
        serving: recipeToUpdate.serving,
        minimumServing: recipeToUpdate.minimumServing,
        servingSteps: recipeToUpdate.servingSteps,
        thumbnail_picture: recipeToUpdate.thumbnail_picture,
        full_picture: recipeToUpdate.full_picture,
        ambassador_id: recipeToUpdate.ambassador_id,
        grillModelIds: mapRecipeGrillModels(recipeGrillModels),
        shouldTimeScale: recipeToUpdate.shouldTimeScale,
        proRecipe: recipeToUpdate.proRecipe,
        adminRecipe: recipeToUpdate.adminRecipe,
        hintTitleDe: recipeToUpdate.hintTitleDe,
        hintTitleEn: recipeToUpdate.hintTitleEn,
        hintDescriptionDe: recipeToUpdate.hintDescriptionDe,
        hintDescriptionEn: recipeToUpdate.hintDescriptionEn,
        pictureAltTextEn: recipeToUpdate.pictureAltTextEn,
        pictureAltTextDe: recipeToUpdate.pictureAltTextDe,
        tutorialIds: recipeTutorials.map((recipeTutorial) => recipeTutorial.tutorialId),
        linkedRecipes: recipeToUpdate.linkedRecipes,
    };
};

const mapRecipeForApi = (recipe: IRecipeForm): IRecipeForm => {
    recipe.linkedRecipes = recipe.linkedRecipes?.map((lr) => lr.id as any);
    return recipe;
};

const handleIngredientUnitDropdown = (
    recipeIngredientsData: IngredientData[],
    ingredientToEdit: IRecipeIngredientsForm,
): Array<IIngredientUnit> => {
    const metricUnit = {
        id: ingredientToEdit.ingredient.metricUnitId,
        name: ingredientToEdit.ingredient.metricUnit,
    };

    const imperialUnit = {
        id: ingredientToEdit.ingredient.imperialUnitId,
        name: ingredientToEdit.ingredient.imperialUnit,
    };

    return [imperialUnit, metricUnit];
};

const filterRecipeIngredientsToDisplay = (
    ingredientsResponse: Array<IngredientData>,
    recipeIngredientsResponse: Array<IRecipeIngredients>,
): Array<IngredientData> => {
    return ingredientsResponse.filter(
        (ingredient) =>
            !recipeIngredientsResponse.find((recipeIngredient) => recipeIngredient.ingredientId === ingredient.id),
    );
};

const mapRecipeGrillModels = (recipeGrillModels: Array<IRecipeGrillModel>): Array<number> => {
    if (genericHelpers.isNullOrUndefined(recipeGrillModels) || recipeGrillModels.length === 0) {
        return [recipeConstants.GRILL_CONSTANTS.NO_GRILL_VALUE];
    }

    return recipeGrillModels.map((value) => value.grillModelId);
};

const cleanStepData = (values: ICookingStepsForm, type: CookingStepTypes): ICookingStepsForm => {
    switch (type) {
        case CookingStepTypes.NORMAL:
            return {
                ...formInitialValues.normalCookingStep,
                id: values.id,
                step_number: values.step_number,
                long_description_en: values.long_description_en,
                long_description_de: values.long_description_de,
            };
        case CookingStepTypes.DASHBOARD_RELEVANT:
            const dashboardBase = {
                ...formInitialValues.dashboardRelevantCookingStep,
                id: values.id,
                step_number: values.step_number,
                long_description_en: values.long_description_en,
                short_description_en: values.short_description_en,
                long_description_de: values.long_description_de,
                short_description_de: values.short_description_de,
                first_zone: Number(values.first_zone) ? values.first_zone : null,
                second_zone: Number(values.second_zone) ? values.second_zone : null,
                third_zone: Number(values.third_zone) ? values.third_zone : null,
                fourth_zone: Number(values.fourth_zone) ? values.fourth_zone : null,
                cooking_step_method: values.cooking_step_method,
                actions: values.actions?.filter((action) => action.actionId && action.actionTimer) || [],
                is_interval: Boolean(values.is_interval) ? values.is_interval : false,
            };

            // Dashboard step with master probe
            if (values.master_probe && !values.duration) {
                return {
                    ...dashboardBase,
                    master_probe: Number(values.master_probe) ? values.master_probe : null,
                };
            }

            // Dashboard step with timer
            return {
                ...dashboardBase,
                duration: Number(values.duration) ? values.duration : null,
            };
        default:
            throw Error('Action not handled');
            return values;
    }
};

const checkDescriptionsFilled = (desc1: string, desc2: string) => {
    return (
        desc1 &&
        desc2 &&
        richTextEditorHelpers.parseRichText(desc1).length > 0 &&
        richTextEditorHelpers.parseRichText(desc2).length > 0
    );
};

export const recipeHelpers = {
    createData,
    recipeCookingStepsRows,
    reorderCookingStepNumber,
    recipeMapper,
    handleIngredientUnitDropdown,
    filterRecipeIngredientsToDisplay,
    cleanStepData,
    checkDescriptionsFilled,
    mapRecipeForApi,
};

const createMeatProbeOrCountDownError = (props: FormikProps<ICookingStepsForm>, type: string): string => {
    const { errors, touched, values } = props;
    const errorMessage = (errors as any).meatProbeOrCountdown;
    return (errors[type] && touched[type]) || (errorMessage && touched[type] && values[type]) ? errorMessage : '';
};

export const errorHelper = {
    createMeatProbeOrCountDownError,
};

import { uniqueId } from 'lodash';

const quizQuestionFormModel = {
    formId: uniqueId('form-'),
    formField: {
        questionEn: {
            name: 'questionEn',
            label: 'Question (English)',
        },
        questionDe: {
            name: 'questionDe',
            label: 'Question (German)',
        },
        correctAnswerEn: {
            name: 'correctAnswerEn',
            label: 'Correct Answer (English)',
        },
        correctAnswerDe: {
            name: 'correctAnswerDe',
            label: 'Correct Answer (German)',
        },
        wrongAnswerEn: {
            name: 'wrongAnswerEn',
            label: 'Wrong Answer (English)',
        },
        wrongAnswerDe: {
            name: 'wrongAnswerDe',
            label: 'Wrong Answer (German)',
        },
        answerExplanationEn: {
            name: 'answerExplanationEn',
            label: 'Answer Explanation (English)',
        },
        answerExplanationDe: {
            name: 'answerExplanationDe',
            label: 'Answer Explanation (German)',
        },
        questionImage: {
            name: 'questionImage',
            label: 'Question Image',
        },
        rewardPoints: {
            name: 'rewardPoints',
            label: 'Reward Points',
        },
        closeIconColorHex: {
            name: 'closeIconColorHex',
            label: 'Close icon color',
        },
        recipeId: {
            name: 'recipeId',
            label: 'Recipe',
        },
        productId: {
            name: 'productId',
            label: 'Product',
        },
        questionImageAltTextEn: {
            name: 'questionImageAltTextEn',
            label: 'Alt text en',
        },
        questionImageAltTextDe: {
            name: 'questionImageAltTextDe',
            label: 'Alt text de',
        },
    },
};
const quizRoundFormModel = {
    formId: uniqueId('form-'),
    formField: {
        quizName: {
            name: 'quizName',
            label: 'Quiz Name',
        },
        startDate: {
            name: 'startDate',
            label: 'Start Date',
        },
        hoursBetweenQuizQuestions: {
            name: 'hoursBetweenQuizQuestions',
            label: 'Hours Between Quiz Questions',
        },
        quizQuestions: {
            name: 'quizQuestions',
            label: 'Quiz Questions',
        },
    },
};

const formModels = {
    quizQuestionFormModel,
    quizRoundFormModel,
};

export default formModels;

import React from 'react';
import { Button, Dialog, FormControl, FormHelperText, Grid, InputAdornment, Paper, TextField } from '@material-ui/core';
import clsx from 'clsx';
import { Form, Formik, FormikProps } from 'formik';
import { MUIDataTableColumnDef, MUIDataTableMeta, MUIDataTableOptions } from 'mui-datatables';
import { PropsWithChildren, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import en from '../../../../../assets/language/en.json';
import DragDropTable from '../../../../shared/components/generics/dragDropTable/DragDropTable';
import LanguageFlag from '../../../../shared/components/generics/languageFlag/LanguageFlag';
import ImageUpload from '../../../../shared/components/imageUpload';
import { IRowsDeleted } from '../../../../shared/components/interfaces';
import { muiDataTablesConstants } from '../../../../shared/constants/mui.datatables.constants';
import { useIdParam } from '../../../../shared/helpers/custom.hooks';
import { DispatchPropsReturnType, genericHelpers } from '../../../../shared/helpers/generics';
import { zodUtils } from '../../../../shared/helpers/zodUtils';
import { AppState } from '../../../../shared/store';
import { troubleshootTables } from '../../../helpers/troubleshoot.datatables';
import { troubleshotActions } from '../../troubleshoot.actions';
import { ITroubleshoot, ITroubleshootPrimaryStep } from '../../troubleshoot.interfaces';
import { formInitialValues } from '../addOrEditTroubleshoot/formModel/form.initial.values';
import formModels from '../addOrEditTroubleshoot/formModel/troubleshoot.form.model';
import validationSchemas from '../addOrEditTroubleshoot/formModel/validation.schema';
import styles from './index.module.scss';
import AltText from '../../../../shared/components/generics/AltText/AltText';

type OwnProps = {
    nextStep: (newEntityId?: number) => void;
    prevStep: () => void;
} & DispatchPropsReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps>;

enum DialogState {
    OPEN,
}

const TroubleshootPrimarySteps = (props: PropsWithChildren<OwnProps>): JSX.Element => {
    const { getTroubleshoot, updateTroubleshootPrimarySteps, prevStep, nextStep } = props;

    const id = useIdParam();

    const [updatingTroubleshoot, setUpdatingTroubleshoot] = useState<ITroubleshoot>(null);
    const [isChanged, setIsChanged] = useState<boolean>(false);
    const [dialogState, setDialogState] = useState<DialogState>(null);
    const [updatingTroubleshootStepIdx, setUpdatingTroubleshootStepIdx] = useState<number | null>(null);
    const [validationErrors, setValidationErrors] = useState({});

    useEffect(() => {
        if (id) {
            getTroubleshoot(id).then((quizQuestion) => {
                setUpdatingTroubleshoot(quizQuestion);
            });
        }
    }, []);

    useEffect(() => {
        const updatingStep = updatingTroubleshoot?.troubleshootingPrimarySteps[updatingTroubleshootStepIdx];
        if (updatingStep) {
            setDialogState(DialogState.OPEN);
            return;
        }
        setDialogState(null);
    }, [updatingTroubleshootStepIdx]);

    useEffect(() => {
        zodUtils
            .zodFormikValidator(validationSchemas.troubleshootWithStepsSchema)(updatingTroubleshoot)
            .then((errors) => {
                setValidationErrors(errors);
            });
    }, [updatingTroubleshoot]);

    const handleSubmit = async (values: ITroubleshoot): Promise<void> => {
        const saveResult = await updateTroubleshootPrimarySteps(values.troubleshootingPrimarySteps, id);
        if (saveResult instanceof Error || !saveResult) {
            return;
        }
        nextStep(id);
    };

    const saveStep = (values: ITroubleshootPrimaryStep) => {
        if (updatingTroubleshootStepIdx !== null) {
            const newSteps = [...updatingTroubleshoot.troubleshootingPrimarySteps];
            newSteps[updatingTroubleshootStepIdx] = { ...values, stepNumber: updatingTroubleshootStepIdx };
            setUpdatingTroubleshoot((prev) => {
                return {
                    ...prev,
                    troubleshootingPrimarySteps: newSteps,
                };
            });
        } else {
            setUpdatingTroubleshoot((prev) => {
                return {
                    ...prev,
                    troubleshootingPrimarySteps: [
                        ...prev.troubleshootingPrimarySteps,
                        {
                            ...values,
                            stepNumber: prev.troubleshootingPrimarySteps.length,
                        },
                    ],
                };
            });
        }
        setUpdatingTroubleshootStepIdx(null);
        setIsChanged(true);
    };

    const dialogClose = () => {
        setDialogState(null);
        setUpdatingTroubleshootStepIdx(null);
    };

    const tableColumns: MUIDataTableColumnDef[] = [
        ...troubleshootTables.troubleshootPrimaryStepsColumns,
        {
            name: muiDataTablesConstants.TABLE_ACTIONS.UPDATE_ACTION,
            options: {
                filter: false,
                sort: false,
                customBodyRender: function EditButton(value: string, tableMeta: MUIDataTableMeta) {
                    return (
                        <Button
                            className={clsx(styles.redButton, 'mr2')}
                            onClick={() => {
                                setUpdatingTroubleshootStepIdx(tableMeta.rowIndex);
                            }}
                        >
                            {en.edit_button_label}
                        </Button>
                    );
                },
            },
        },
    ];

    tableColumns.splice(0, 0, {
        name: 'stepNumber',
        label: en.step_number,
        options: {
            filter: false,
            sort: false,
            customBodyRender: function stepNumberRenderer(value: string | number, meta) {
                return +value + 1;
            },
        },
    });

    const tableOptions: MUIDataTableOptions = {
        filter: false,
        responsive: muiDataTablesConstants.RESPONSIVE.STANDARD,
        pagination: false,
        selectableRows: muiDataTablesConstants.SELECTABLE_ROWS.SINGLE,
        onRowsDelete: (rowsDeleted: IRowsDeleted) => {
            const stepsToRemoveIndex = rowsDeleted.data.map((row) => row.index);
            setUpdatingTroubleshoot((prev) => {
                const newState = prev.troubleshootingPrimarySteps
                    // Filter out the deleted steps
                    .filter((_, index) => !stepsToRemoveIndex.includes(index))
                    // Map the new index to correct step_number
                    .map((entity, index) => ({
                        ...entity,
                        stepNumber: index,
                    }));
                return {
                    ...prev,
                    troubleshootingPrimarySteps: newState,
                };
            });
            setIsChanged(true);
        },
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const items = genericHelpers
            .reorder(updatingTroubleshoot.troubleshootingPrimarySteps, result.source.index, result.destination.index)
            .map((item, index) => ({
                ...item,
                stepNumber: index,
            }));

        setIsChanged(true);
        setUpdatingTroubleshoot((prevState) => ({
            ...prevState,
            troubleshootingPrimarySteps: items,
        }));
    };

    return (
        <Paper className={clsx(styles.paper, 'mt16')} variant={'outlined'}>
            <div className={clsx(styles.container, 'p4')}>
                <Grid item xs={12} className={styles.topLeftNavigation}>
                    <Button
                        className={clsx(styles.redButton, 'mr2', 'mt4', 'mb4')}
                        variant={'outlined'}
                        onClick={() => {
                            setDialogState(DialogState.OPEN);
                        }}
                    >
                        ADD NEW PRIMARY STEP
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <DragDropTable
                        title={'Troubleshoot Primary Steps'}
                        data={updatingTroubleshoot?.troubleshootingPrimarySteps}
                        columns={tableColumns}
                        options={tableOptions}
                        onDragEnd={onDragEnd}
                    />
                </Grid>
                <AddEditDialog
                    dialogState={dialogState}
                    setDialogState={setDialogState}
                    saveStep={saveStep}
                    updatingStep={updatingTroubleshoot?.troubleshootingPrimarySteps?.[updatingTroubleshootStepIdx]}
                    onClose={dialogClose}
                />
                <div className={clsx(styles.bottomNavigation, 'mt4', 'mb2')}>
                    <TroubleShootStepErrors validationErrors={validationErrors} />
                    <Button
                        className={clsx(styles.redButton)}
                        variant={'outlined'}
                        onClick={() => handleSubmit(updatingTroubleshoot)}
                        disabled={Object.keys(validationErrors).length > 0 || !isChanged}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </Paper>
    );
};

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = {
    getTroubleshoot: troubleshotActions.getTroubleshoot,
    updateTroubleshootPrimarySteps: troubleshotActions.updateTroubleshootPrimarySteps,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(TroubleshootPrimarySteps);

type DialogProps = {
    dialogState: DialogState;
    setDialogState: (newState: DialogState) => void;
    saveStep: (step: ITroubleshootPrimaryStep) => void;
    updatingStep?: ITroubleshootPrimaryStep;
    onClose: () => void;
};

const AddEditDialog = (props: DialogProps): JSX.Element => {
    const { dialogState, setDialogState, saveStep, updatingStep, onClose } = props;
    return (
        <Dialog
            onClose={onClose}
            aria-labelledby="customized-dialog-title"
            open={dialogState !== null}
            maxWidth={'md'}
            fullWidth={true}
            className={styles.dialog}
        >
            {renderDialogContent()}
        </Dialog>
    );

    function dialogSaveStep(step: ITroubleshootPrimaryStep) {
        saveStep(step);
        setDialogState(null);
    }

    function renderDialogContent() {
        switch (dialogState) {
            default:
                return (
                    <BasicStepDialog
                        key={'ChooseStepTypeDialog'}
                        saveStep={dialogSaveStep}
                        updatingStep={updatingStep}
                    />
                );
        }
    }
};

type BasicStepDialogProps = {
    saveStep: (step: ITroubleshootPrimaryStep) => void;
    updatingStep?: ITroubleshootPrimaryStep;
};

const BasicStepDialog = (props: BasicStepDialogProps): JSX.Element => {
    const { saveStep, updatingStep } = props;

    const form = formModels.troubleshootPrimaryStepFormModel.formField;

    return (
        <div className={styles.dialogContents}>
            <Formik
                initialValues={updatingStep ?? formInitialValues.troubleshootPrimaryStep}
                onSubmit={(values) => {
                    saveStep(values);
                }}
                validate={zodUtils.zodFormikValidator(validationSchemas.troubleshootPrimaryStepSchema)}
                enableReinitialize={true}
            >
                {(formProps: FormikProps<ITroubleshootPrimaryStep>) => (
                    <Form className={clsx(styles.addForm, styles.pixelated)}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                    <TextField
                                        className={styles.formInput}
                                        label={form.titleEn.label}
                                        name={form.titleEn.name}
                                        type="text"
                                        required
                                        onChange={formProps.handleChange}
                                        onBlur={formProps.handleBlur}
                                        value={formProps.values.titleEn}
                                        variant="outlined"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <LanguageFlag country="us" />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                        {formProps.errors.titleEn && formProps.touched.titleEn
                                            ? formProps.errors.titleEn
                                            : ''}
                                    </FormHelperText>
                                </FormControl>
                                <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                    <ImageUpload
                                        fileValue={formProps.values.thumbnailEn as string}
                                        label={form.thumbnailEn.label}
                                        onChange={(file) => {
                                            formProps.setFieldValue(form.thumbnailEn.name, file);
                                        }}
                                        inputFieldName={form.thumbnailEn.name}
                                        key={form.thumbnailEn.name}
                                    />
                                    <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                        {formProps.errors.thumbnailEn && formProps.touched.thumbnailEn
                                            ? formProps.errors.thumbnailEn
                                            : ''}
                                    </FormHelperText>
                                </FormControl>
                                <AltText
                                    {...formProps}
                                    altTextDeFieldMeta={form.thumbnailAltTextDe}
                                    altTextEnFieldMeta={form.thumbnailAltTextEn}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                    <TextField
                                        className={styles.formInput}
                                        label={form.titleDe.label}
                                        name={form.titleDe.name}
                                        type="text"
                                        required
                                        onChange={formProps.handleChange}
                                        onBlur={formProps.handleBlur}
                                        value={formProps.values.titleDe}
                                        variant="outlined"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <LanguageFlag country="de" />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                        {formProps.errors.titleDe && formProps.touched.titleDe
                                            ? formProps.errors.titleDe
                                            : ''}
                                    </FormHelperText>
                                </FormControl>
                                <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                    <ImageUpload
                                        fileValue={formProps.values.thumbnailDe as string}
                                        label={form.thumbnailDe.label}
                                        onChange={(file) => {
                                            formProps.setFieldValue(form.thumbnailDe.name, file);
                                        }}
                                        inputFieldName={form.thumbnailDe.name}
                                        key={form.thumbnailDe.name}
                                    />
                                    <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                        {formProps.errors.thumbnailDe && formProps.touched.thumbnailDe
                                            ? formProps.errors.thumbnailDe
                                            : ''}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={clsx(styles.bottomNavigation)}>
                                    <Button
                                        className={clsx(styles.redButton, 'mr2', 'mt4', 'mb4')}
                                        variant={'outlined'}
                                        type="submit"
                                        disabled={!formProps.isValid || !formProps.dirty}
                                    >
                                        Save Step
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

function TroubleShootStepErrors(props: { validationErrors: Record<string, unknown> }) {
    const { validationErrors } = props;
    const errorKeys = Object.keys(validationErrors);
    if (errorKeys?.length > 0) {
        return (
            <FormHelperText className={clsx(styles.error_span, 'mt2', 'mr4')}>
                {errorKeys.map((key) => {
                    return (
                        <>
                            <span key={key}>{`${key}: ${validationErrors[key]}`}</span>
                            <br />
                        </>
                    );
                })}
            </FormHelperText>
        );
    }
    return null;
}

import { FormControl, FormGroup, FormHelperText, TextField, InputAdornment } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import clsx from 'clsx';
import LanguageFlag from '../languageFlag/LanguageFlag';
import { FormikProps } from 'formik';
import { useEffect } from 'react';

interface AltTextProps {
    altTextDeFieldMeta: {
        name?: string;
        label?: string;
    };
    altTextEnFieldMeta: {
        name?: string;
        label?: string;
    };
}

type AltTextComponentProps<T> = FormikProps<T> & AltTextProps;

const AltText = <T extends Record<string, any>>(props: AltTextComponentProps<T>): React.ReactElement => {
    const { handleBlur, setFieldValue, handleChange, values, errors, touched, altTextDeFieldMeta, altTextEnFieldMeta } =
        props;

    const altTextDeKey = altTextDeFieldMeta.name;
    const altTextEnKey = altTextEnFieldMeta.name;

    useEffect(() => {
        if (values[altTextDeKey] == null || typeof values[altTextDeKey] === 'undefined') {
            setFieldValue(altTextDeKey, '');
        }

        if (values[altTextEnKey] == null || typeof values[altTextEnKey] === 'undefined') {
            setFieldValue(altTextEnKey, '');
        }
    }, [values[altTextDeKey], values[altTextEnKey]]);

    return (
        <FormGroup className={clsx(styles.altTextGroup)}>
            <FormControl className={clsx('mb4')}>
                <TextField
                    name={altTextDeFieldMeta.name}
                    label={altTextDeFieldMeta.label}
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values[altTextDeKey] ?? ''}
                    variant="outlined"
                    multiline
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <LanguageFlag country="de" />
                            </InputAdornment>
                        ),
                    }}
                />
                <FormHelperText className={clsx(styles.altTextError, 'mt2')}>
                    {errors[altTextDeKey] && touched[altTextDeKey] ? errors[altTextDeKey] : ''}
                </FormHelperText>
            </FormControl>
            <FormControl className={clsx('mb4')}>
                <TextField
                    name={altTextEnFieldMeta.name}
                    label={altTextEnFieldMeta.label}
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values[altTextEnKey] ?? ''}
                    variant="outlined"
                    multiline
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <LanguageFlag country="us" />
                            </InputAdornment>
                        ),
                    }}
                />
                <FormHelperText className={clsx(styles.altTextError, 'mt2')}>
                    {errors[altTextEnKey] && touched[altTextEnKey] ? errors[altTextEnKey] : ''}
                </FormHelperText>
            </FormControl>
        </FormGroup>
    );
};

AltText.propTypes = {
    altTextDeFieldMeta: PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    }).isRequired,
    altTextEnFieldMeta: PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    }),
};

export default AltText;

import React, { PropsWithChildren, useEffect, useState } from 'react';
import {
    Button,
    FormControl,
    FormHelperText,
    Grid,
    InputAdornment,
    MenuItem,
    Paper,
    TextField,
    Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import clsx from 'clsx';
import { Form, Formik, FormikProps } from 'formik';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import en from '../../../../../assets/language/en.json';
import ColorInputField from '../../../../shared/components/generics/colorInputField/ColorInputField';
import LanguageFlag from '../../../../shared/components/generics/languageFlag/LanguageFlag';
import ImageUploadCropper from '../../../../shared/components/imageUploadCropper';
import { globalConstants } from '../../../../shared/constants/global.constants';
import { useIdParam } from '../../../../shared/helpers/custom.hooks';
import { DispatchPropsReturnType } from '../../../../shared/helpers/generics';
import { zodUtils } from '../../../../shared/helpers/zodUtils';
import { AppState } from '../../../../shared/store';
import { recipeAction } from '../../../recipe/recipe.actions';
import { IRecipeData } from '../../../recipe/recipe.interfaces';
import { upsellingActions } from '../../../upselling/upselling.actions';
import { IProduct } from '../../../upselling/upselling.interfaces';
import { quizQuestionActions } from '../../quiz.questions.actions';
import { quizQuestionEndpoints } from '../../quiz.questions.endpoints';
import { IQuizQuestion } from '../../quiz.questions.interfaces';
import { formInitialValues } from './formModel/form.initial.values';
import formModels from './formModel/quiz.question.form.model';
import validationSchemas from './formModel/validation.schema';
import styles from './index.module.scss';
import AltText from '../../../../shared/components/generics/AltText/AltText';

type OwnProps = DispatchPropsReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const AddOrEditQuizQuestion = (props: PropsWithChildren<OwnProps>): JSX.Element => {
    const { addQuizQuestion, updateQuizQuestion, getQuizQuestion } = props;

    const id = useIdParam();

    const [updatingQuizQuestion, setUpdatingQuizQuestion] = useState<IQuizQuestion>(null);

    const formInitials = updatingQuizQuestion ?? formInitialValues.quizQuestion;
    const form = formModels.quizQuestionFormModel.formField;
    useEffect(() => {
        if (id) {
            getQuizQuestion(+id).then((quizQuestion) => {
                setUpdatingQuizQuestion(quizQuestion);
            });
        }
    }, []);

    const history = useHistory();
    const handleSubmit = async (values: IQuizQuestion): Promise<void> => {
        const saveResult = await (updatingQuizQuestion ? updateQuizQuestion(values, +id) : addQuizQuestion(values));
        if (saveResult instanceof Error) {
            return;
        }
        history.push(quizQuestionEndpoints.QUIZ_QUESTION_ROUTES.QUIZ_QUESTIONS);
    };

    return (
        <Paper className={clsx(styles.productPaper, 'mt16')} variant={'outlined'}>
            <div className={clsx(styles.product, 'p4')}>
                <Formik
                    initialValues={formInitials}
                    onSubmit={async (values: IQuizQuestion, { setSubmitting }) => {
                        await handleSubmit(values);
                        setSubmitting(false);
                    }}
                    validate={zodUtils.zodFormikValidator(validationSchemas.zodValidationSchema)}
                    enableReinitialize={true}
                >
                    {(formProps: FormikProps<IQuizQuestion>) => {
                        return (
                            <Form className={styles.addProductForm}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="h3"
                                            gutterBottom
                                            className={clsx(styles.typography, 'mt8')}
                                        >
                                            {`${id ? 'Edit' : 'Add'} Quiz Question`}
                                        </Typography>
                                    </Grid>
                                    {/* Left grid container for main input form */}
                                    <Grid item xs={8} container spacing={2}>
                                        <Grid item xs={6}>
                                            <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                                <TextField
                                                    className={styles.formInput}
                                                    label={form.questionEn.label}
                                                    name={form.questionEn.name}
                                                    type="text"
                                                    required
                                                    onChange={formProps.handleChange}
                                                    onBlur={formProps.handleBlur}
                                                    value={formProps.values.questionEn}
                                                    variant="outlined"
                                                    multiline
                                                    minRows={4}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <LanguageFlag country="us" />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                    {formProps.errors.questionEn && formProps.touched.questionEn
                                                        ? formProps.errors.questionEn
                                                        : ''}
                                                </FormHelperText>
                                            </FormControl>
                                            <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                                <TextField
                                                    className={styles.formInput}
                                                    label={form.correctAnswerEn.label}
                                                    name={form.correctAnswerEn.name}
                                                    type="text"
                                                    required
                                                    onChange={formProps.handleChange}
                                                    onBlur={formProps.handleBlur}
                                                    value={formProps.values.correctAnswerEn}
                                                    variant="outlined"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <LanguageFlag country="us" />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                    {formProps.errors.correctAnswerEn &&
                                                    formProps.touched.correctAnswerEn
                                                        ? formProps.errors.correctAnswerEn
                                                        : ''}
                                                </FormHelperText>
                                            </FormControl>
                                            <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                                <TextField
                                                    className={styles.formInput}
                                                    label={form.wrongAnswerEn.label}
                                                    name={form.wrongAnswerEn.name}
                                                    type="text"
                                                    required
                                                    onChange={formProps.handleChange}
                                                    onBlur={formProps.handleBlur}
                                                    value={formProps.values.wrongAnswerEn}
                                                    variant="outlined"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <LanguageFlag country="us" />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                    {formProps.errors.wrongAnswerEn && formProps.touched.wrongAnswerEn
                                                        ? formProps.errors.wrongAnswerEn
                                                        : ''}
                                                </FormHelperText>
                                            </FormControl>
                                            <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                                <TextField
                                                    className={styles.formInput}
                                                    label={form.answerExplanationEn.label}
                                                    name={form.answerExplanationEn.name}
                                                    type="text"
                                                    required
                                                    onChange={formProps.handleChange}
                                                    onBlur={formProps.handleBlur}
                                                    value={formProps.values.answerExplanationEn}
                                                    variant="outlined"
                                                    multiline
                                                    minRows={4}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <LanguageFlag country="us" />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                    {formProps.errors.answerExplanationEn &&
                                                    formProps.touched.answerExplanationEn
                                                        ? formProps.errors.answerExplanationEn
                                                        : ''}
                                                </FormHelperText>
                                            </FormControl>
                                            <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                                <ColorInputField
                                                    fieldVariant="textfield"
                                                    style={{
                                                        height: '56px',
                                                    }}
                                                    colors={[
                                                        { colorHex: '#E9E9E9', name: 'White' },
                                                        { colorHex: '#F41717', name: 'Red' },
                                                        { colorHex: '#000000', name: 'Black' },
                                                    ]}
                                                    key={formProps.values.closeIconColorHex}
                                                    handleColorChange={(color) =>
                                                        formProps.setFieldValue(form.closeIconColorHex.name, color)
                                                    }
                                                    className={styles.formInput}
                                                    label={form.closeIconColorHex.label}
                                                    name={form.closeIconColorHex.name}
                                                    type="text"
                                                    required
                                                    onChange={formProps.handleChange}
                                                    onBlur={formProps.handleBlur}
                                                    value={formProps.values.closeIconColorHex}
                                                    variant="outlined"
                                                />
                                                <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                    {formProps.errors.closeIconColorHex &&
                                                    formProps.touched.closeIconColorHex
                                                        ? formProps.errors.closeIconColorHex
                                                        : ''}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                                <TextField
                                                    className={styles.formInput}
                                                    label={form.questionDe.label}
                                                    name={form.questionDe.name}
                                                    type="text"
                                                    required
                                                    onChange={formProps.handleChange}
                                                    onBlur={formProps.handleBlur}
                                                    value={formProps.values.questionDe}
                                                    variant="outlined"
                                                    multiline
                                                    minRows={4}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <LanguageFlag country="de" />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                    {formProps.errors.questionDe && formProps.touched.questionDe
                                                        ? formProps.errors.questionDe
                                                        : ''}
                                                </FormHelperText>
                                            </FormControl>
                                            <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                                <TextField
                                                    className={styles.formInput}
                                                    label={form.correctAnswerDe.label}
                                                    name={form.correctAnswerDe.name}
                                                    type="text"
                                                    required
                                                    onChange={formProps.handleChange}
                                                    onBlur={formProps.handleBlur}
                                                    value={formProps.values.correctAnswerDe}
                                                    variant="outlined"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <LanguageFlag country="de" />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                    {formProps.errors.correctAnswerDe &&
                                                    formProps.touched.correctAnswerDe
                                                        ? formProps.errors.correctAnswerDe
                                                        : ''}
                                                </FormHelperText>
                                            </FormControl>
                                            <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                                <TextField
                                                    className={styles.formInput}
                                                    label={form.wrongAnswerDe.label}
                                                    name={form.wrongAnswerDe.name}
                                                    type="text"
                                                    required
                                                    onChange={formProps.handleChange}
                                                    onBlur={formProps.handleBlur}
                                                    value={formProps.values.wrongAnswerDe}
                                                    variant="outlined"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <LanguageFlag country="de" />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                    {formProps.errors.wrongAnswerDe && formProps.touched.wrongAnswerDe
                                                        ? formProps.errors.wrongAnswerDe
                                                        : ''}
                                                </FormHelperText>
                                            </FormControl>
                                            <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                                <TextField
                                                    className={styles.formInput}
                                                    label={form.answerExplanationDe.label}
                                                    name={form.answerExplanationDe.name}
                                                    type="text"
                                                    required
                                                    onChange={formProps.handleChange}
                                                    onBlur={formProps.handleBlur}
                                                    value={formProps.values.answerExplanationDe}
                                                    variant="outlined"
                                                    multiline
                                                    minRows={4}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <LanguageFlag country="de" />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                    {formProps.errors.answerExplanationDe &&
                                                    formProps.touched.answerExplanationDe
                                                        ? formProps.errors.answerExplanationDe
                                                        : ''}
                                                </FormHelperText>
                                            </FormControl>
                                            <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                                <TextField
                                                    className={styles.formInput}
                                                    label={form.rewardPoints.label}
                                                    name={form.rewardPoints.name}
                                                    type="number"
                                                    required
                                                    onChange={formProps.handleChange}
                                                    onBlur={formProps.handleBlur}
                                                    value={formProps.values.rewardPoints}
                                                    variant="outlined"
                                                />
                                                <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                    {formProps.errors.rewardPoints && formProps.touched.rewardPoints
                                                        ? formProps.errors.rewardPoints
                                                        : ''}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <LinkToComponent
                                            formProps={formProps}
                                            formFields={form}
                                            formInitials={formInitials}
                                        />
                                    </Grid>
                                    {/* Right grid container for media upload */}
                                    <Grid item xs={4} container spacing={2}>
                                        <Grid item xs={12}>
                                            <FormControl className={clsx(styles.formControl, 'mb4')}>
                                                <ImageUploadCropper
                                                    label={form.questionImage.label}
                                                    fileValue={formProps.values.questionImage as string}
                                                    aspectRatio={1 / 1}
                                                    onChange={(file) =>
                                                        formProps.setFieldValue(form.questionImage.name, file)
                                                    }
                                                />
                                            </FormControl>
                                            <AltText
                                                {...formProps}
                                                altTextDeFieldMeta={form.questionImageAltTextDe}
                                                altTextEnFieldMeta={form.questionImageAltTextEn}
                                            />{' '}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className={clsx(styles.bottomNavigation, 'mt12')}>
                                            {(() => {
                                                const errorKeys = Object.keys(formProps.errors);
                                                if (errorKeys?.length > 0 && errorKeys?.length <= 3) {
                                                    return (
                                                        <FormHelperText
                                                            className={clsx(styles.error_span, 'mt2', 'mr4')}
                                                        >
                                                            {errorKeys.map((key) => {
                                                                return (
                                                                    <p
                                                                        key={form[`${key}.label`] || key}
                                                                    >{`${key}: ${formProps.errors[key]}`}</p>
                                                                );
                                                            })}
                                                        </FormHelperText>
                                                    );
                                                }
                                                return null;
                                            })()}
                                            <Button
                                                type="submit"
                                                className={clsx(styles.saveProduct, 'mr2', 'mt4')}
                                                disabled={formProps.isSubmitting || !formProps.isValid}
                                            >
                                                {en.save_button_label}
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </Paper>
    );
};

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = {
    getQuizQuestion: quizQuestionActions.getQuizQuestion,
    addQuizQuestion: quizQuestionActions.addQuizQuestion,
    updateQuizQuestion: quizQuestionActions.updateQuizQuestion,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(AddOrEditQuizQuestion);

type LinkToComponentProps = {
    formFields: typeof formModels.quizQuestionFormModel.formField;
    formProps: FormikProps<IQuizQuestion>;
    formInitials: IQuizQuestion;
} & DispatchPropsReturnType<typeof mapLinkToComponentDispatchToProps> &
    ReturnType<typeof mapLinkToComponentStateToProps>;

const mapLinkToComponentStateToProps = (state: AppState) => ({
    recipes: state.recipe.recipes,
    products: state.products.products,
});

const mapLinkToComponentDispatchToProps = {
    getRecipes: recipeAction.getRecipes,
    getProducts: upsellingActions.getProducts,
};

const linkToEnum = ['Product', 'Recipe', 'None'] as const;
type LinkToEnum = (typeof linkToEnum)[number];

const LinkToComponent = connect(
    mapLinkToComponentStateToProps,
    mapLinkToComponentDispatchToProps,
)((props: LinkToComponentProps): JSX.Element => {
    const { formFields, formProps, getProducts, getRecipes, products, recipes, formInitials } = props;

    const [linkTo, setLinkTo] = useState<LinkToEnum>('None');

    useEffect(() => {
        if (formInitials.productId) {
            setLinkTo('Product');
            return;
        }
        if (formInitials.recipeId) {
            setLinkTo('Recipe');
            return;
        }
        setLinkTo('None');
    }, [formInitials]);

    useEffect(() => {
        if (linkTo === 'Product') {
            formProps.setFieldValue(formFields.recipeId.name, null);
            return;
        }
        if (linkTo === 'Recipe') {
            formProps.setFieldValue(formFields.productId.name, null);
            return;
        }
        if (linkTo === 'None') {
            formProps.setFieldValue(formFields.productId.name, null);
            formProps.setFieldValue(formFields.recipeId.name, null);
        }
    }, [linkTo]);

    useEffect(() => {
        getProducts(globalConstants.PAGINATION_NO_LIMIT);
        getRecipes(globalConstants.PAGINATION_NO_LIMIT);
    }, [getProducts, getRecipes]);

    function renderSearchInput(): JSX.Element {
        switch (linkTo) {
            case 'Product':
                return (
                    <FormControl className={clsx(styles.formControl, 'mb4')}>
                        <Autocomplete
                            getOptionLabel={(option: IProduct) => option.titleEn}
                            getOptionSelected={(option, value) => option.id === value.id}
                            options={products}
                            onChange={(_, value: IProduct) => {
                                formProps.setFieldValue(formFields.productId.name, value?.id ?? null);
                            }}
                            value={products.find((product) => product.id === formProps.values.productId) ?? null}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={formFields.productId.label}
                                    className={styles.formInput}
                                    variant="outlined"
                                    InputProps={{
                                        ...params.InputProps,
                                    }}
                                />
                            )}
                        />
                        <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                            {formProps.errors.productId && formProps.touched.productId
                                ? formProps.errors.productId
                                : ''}
                        </FormHelperText>
                    </FormControl>
                );
            case 'Recipe':
                return (
                    <FormControl className={clsx(styles.formControl, 'mb4')}>
                        <Autocomplete
                            getOptionLabel={(option: IRecipeData) => option.nameEn}
                            getOptionSelected={(option, value) => option.id === value.id}
                            options={recipes}
                            onChange={(_, value: IRecipeData) => {
                                formProps.setFieldValue(formFields.recipeId.name, value?.id ?? null);
                            }}
                            value={recipes.find((recipe) => recipe.id === formProps.values.recipeId) ?? null}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={formFields.recipeId.label}
                                    className={styles.formInput}
                                    variant="outlined"
                                    InputProps={{
                                        ...params.InputProps,
                                    }}
                                />
                            )}
                        />
                        <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                            {formProps.errors.recipeId && formProps.touched.recipeId ? formProps.errors.recipeId : ''}
                        </FormHelperText>
                    </FormControl>
                );
            default:
                return null;
        }
    }

    return (
        <Grid item xs={12} container spacing={2}>
            <Grid item xs={4}>
                <FormControl className={clsx(styles.formControl, 'mb4')} required>
                    <TextField
                        className={styles.formInput}
                        label={'Link'}
                        type="text"
                        required
                        onChange={(event) => {
                            formProps.handleChange(event);
                            setLinkTo(event.target.value as LinkToEnum);
                        }}
                        onBlur={formProps.handleBlur}
                        value={linkTo}
                        variant="outlined"
                        select
                        SelectProps={{
                            multiple: false,
                        }}
                    >
                        {linkToEnum.map((linkToEnumValue) => {
                            return (
                                <MenuItem key={linkToEnumValue} value={linkToEnumValue}>
                                    {linkToEnumValue}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                </FormControl>
            </Grid>
            <Grid item xs={8}>
                {renderSearchInput()}
            </Grid>
        </Grid>
    );
});

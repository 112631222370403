import { convertToRaw, EditorState } from 'draft-js';

const tutorial = {
    title_en: '',
    title_de: '',
    header_picture: '',
    headerPictureAltTextEn: '',
    headerPictureAltTextDe: '',
};

const tutorialSteps = {
    text_en: JSON.stringify(convertToRaw(EditorState.createEmpty().getCurrentContent())),
    text_de: JSON.stringify(convertToRaw(EditorState.createEmpty().getCurrentContent())),
    file_en: '',
    file_de: '',
    fileAltTextEn: '',
    fileAltTextDe: '',
};

const tutorialStepsDescriptions = {
    text_en: JSON.stringify(convertToRaw(EditorState.createEmpty().getCurrentContent())),
    text_de: JSON.stringify(convertToRaw(EditorState.createEmpty().getCurrentContent())),
};

export const formInitialValues = {
    tutorial,
    tutorialSteps,
    tutorialStepsDescriptions,
};

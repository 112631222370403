import * as Yup from 'yup';
import formModels from './form.model';
import { ObjectSchema } from 'yup';
import { ITutorialForm, ITutorialStepForm } from '../../../tutorial.interfaces';
import { richTextEditorHelpers } from '../../../../../shared/helpers/rich.text.editor.helpers';
import en from '../../../../../../assets/language/en.json';

const {
    formField: { title_en, title_de },
} = formModels.tutorialFormModel;

const {
    formField: { text_en, text_de },
} = formModels.tutorialStepFormModel;

const tutorialValidationSchema: ObjectSchema<ITutorialForm> = Yup.object()
    .defined()
    .shape({
        title_en: Yup.string().required(`${title_en.requiredErrorMsg}`).defined(),
        title_de: Yup.string().required(`${title_de.requiredErrorMsg}`).defined(),
        header_picture: Yup.mixed(),
        headerPictureAltTextDe: Yup.string(),
        headerPictureAltTextEn: Yup.string(),
    });

const tutorialStepValidationSchema: ObjectSchema<ITutorialStepForm> = Yup.object()
    .defined()
    .shape({
        text_en: Yup.string().defined(),
        text_de: Yup.string().defined(),
        file_en: Yup.mixed(),
        file_de: Yup.mixed(),
        areBothTextsFilled: Yup.boolean().when([text_en.name, text_de.name], {
            is: (text_en, text_de) => {
                return (
                    richTextEditorHelpers.parseRichText(text_en).length > 0 &&
                    richTextEditorHelpers.parseRichText(text_de).length > 0
                );
            },
            then: Yup.bool(),
            otherwise: Yup.bool().required(`${en.descriptions_required_error_msg}`),
        }),
    });

const validationSchemas = {
    tutorialValidationSchema,
    tutorialStepValidationSchema,
};

export default validationSchemas;

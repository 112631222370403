import React, { PropsWithChildren, ReactElement, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styles from '../index.module.scss';
import { Button, Card, CardActionArea, CardActions, Grid } from '@material-ui/core';
import { AppState } from '../../../../../shared/store';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import en from '../../../../../../assets/language/en.json';
import de from '../../../../../../assets/language/de.json';
import { globalConstants } from '../../../../../shared/constants/global.constants';
import { ITutorialStep, ITutorialStepFormComponent, ITutorialStepForm } from '../../../tutorial.interfaces';
import { tutorialActions } from '../../../tutorial.actions';
import { formInitialValues } from '../formModel/form.initial.values';
import formModels from '../formModel/form.model';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { Form, Formik, FormikProps } from 'formik';
import ImportFile from '../../../../import/pages/import.file';
import validationSchemas from '../formModel/validation.schema';
import clsx from 'clsx';
import DialogTextEditor from '../../../../../shared/components/generics/textEditorModal/TextEditor';
import Backdrop from '../../../../../shared/components/generics/BackDrop';
import TextEditorValueRender from '../../../../../shared/components/generics/textEditorValuesRender/Box';
import { useIdParam } from '../../../../../shared/helpers/custom.hooks';
import AltText from '../../../../../shared/components/generics/AltText/AltText';

type Props = typeof formModels.tutorialStepFormModel & ITutorialStepFormComponent & ReturnType<typeof mapStateToProps>;

const TutorialStep = (props: PropsWithChildren<Props>): ReactElement => {
    const hiddenMediaInput = React.useRef(null);
    const hiddenMediaDeInput = React.useRef(null);
    const [tutorialStepToEdit, setTutorialStepToEdit] = useState<ITutorialStep | null>(null);
    const [open, setOpen] = useState<string | boolean>(false);

    const formInitials = formInitialValues.tutorialSteps;
    const {
        formField: { file_en, file_de, fileAltTextDe, fileAltTextEn },
    } = formModels.tutorialStepFormModel;

    const {
        prevStep,
        tutorialData,
        formField: { text_en, text_de },
    } = props;
    const getTutorialSteps = useCallback(async () => {
        if (tutorialData) {
            const tutorialStepResponse = await props.getTutorialSteps(tutorialData.id);

            setTutorialStepToEdit(tutorialStepResponse.tutorialStep);
        }
    }, [tutorialData]);

    useEffect(() => {
        (async () => await getTutorialSteps())();
    }, []);

    const handleUploadClick = (type: string): void => {
        if (type === file_de.name) {
            hiddenMediaDeInput.current.click();
        } else {
            hiddenMediaInput.current.click();
        }
    };
    const handleSubmit = async (values: ITutorialStepForm): Promise<void> => {
        tutorialStepToEdit ? await handleUpdate(values) : await handleInsert(values);
    };

    const handleUpdate = async (values: ITutorialStepForm): Promise<void> => {
        const tutorialStepToSave: ITutorialStepForm = {
            ...values,
            tutorial_id: tutorialData.id,
        };

        await props.updateTutorialStep(tutorialStepToSave).then(() => {
            props.handleCompleteStep(tutorialData, 'tutorial-products');
        });
    };

    const handleInsert = async (values: ITutorialStepForm): Promise<void> => {
        const newTutorialStep: ITutorialStepForm = {
            ...values,
            tutorial_id: tutorialData.id,
        };

        await props.addTutorialStep(newTutorialStep).then(() => {
            props.handleCompleteStep(tutorialData, 'tutorial-products');
        });
    };

    const handleNext = (): void => {
        props.nextStep(tutorialData);
    };

    const handleDialogClick = (type?: string): void => {
        setOpen(type ? type : false);
    };

    return (
        <div className={clsx(styles.tutorial, 'mt8')}>
            <Backdrop isOpen={props.loading} progressUpload={props.progressUpload} />
            <Formik
                initialValues={tutorialStepToEdit ? tutorialStepToEdit : formInitials}
                validationSchema={validationSchemas.tutorialStepValidationSchema}
                onSubmit={async (values: ITutorialStepForm, { setSubmitting }) => {
                    await handleSubmit(values);
                    setSubmitting(false);
                }}
                enableReinitialize={true}
            >
                {(props: FormikProps<ITutorialStepForm>) => (
                    <Form>
                        <Grid container spacing={5}>
                            <Grid item xs={6}>
                                <div className={styles.rte}>
                                    <DialogTextEditor
                                        onSave={props.setFieldValue}
                                        value={props.values.text_en}
                                        type={text_en}
                                        openDialog={open === text_en.name}
                                        handleDialogClick={handleDialogClick}
                                        alert={en.step_description_saved_successfully}
                                    />

                                    <TextEditorValueRender
                                        values={props.values.text_en}
                                        label={text_en.label}
                                        handleDialogClick={() => handleDialogClick(text_en.name)}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className={styles.rte}>
                                    <DialogTextEditor
                                        onSave={props.setFieldValue}
                                        value={props.values.text_de}
                                        type={text_de}
                                        openDialog={open === text_de.name}
                                        handleDialogClick={handleDialogClick}
                                        alert={en.step_description_saved_successfully}
                                    />

                                    <TextEditorValueRender
                                        values={props.values.text_de}
                                        label={text_de.label}
                                        handleDialogClick={() => handleDialogClick(text_de.name)}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className={styles.mediaContainer}>
                                    <Card>
                                        <CardActionArea className={styles.actionArea}>
                                            <ImportFile
                                                file={props.values.file_en}
                                                title={file_en.label}
                                                style={styles.imgCard}
                                                remove={() => props.setFieldValue(file_en.name, '')}
                                            />
                                        </CardActionArea>
                                        <CardActions>
                                            <input
                                                accept="file/"
                                                id="importFile"
                                                type="file"
                                                className={styles.fileInput}
                                                ref={hiddenMediaInput}
                                                onChange={(event) => {
                                                    props.setFieldValue(
                                                        file_en.name,
                                                        event.currentTarget.files[
                                                            globalConstants.KEYS.FIRST_FILE_INDEX
                                                        ],
                                                    );
                                                }}
                                            />
                                            <Button
                                                className={styles.uploadImg}
                                                onClick={() => handleUploadClick(file_en.name)}
                                                startIcon={<CloudUploadIcon />}
                                            >
                                                {en.upload_step_picture_video}
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </div>
                                <div className={clsx(styles.topMargin)}>
                                    <AltText
                                        {...props}
                                        altTextDeFieldMeta={fileAltTextDe}
                                        altTextEnFieldMeta={fileAltTextEn}
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={6}>
                                <div className={styles.mediaContainer}>
                                    <Card>
                                        <CardActionArea className={styles.actionArea}>
                                            <ImportFile
                                                file={props.values.file_de}
                                                title={file_de.label}
                                                style={styles.imgCard}
                                                remove={() => {
                                                    props.setFieldValue(file_de.name, '');
                                                }}
                                            />
                                        </CardActionArea>
                                        <CardActions>
                                            <input
                                                accept="file/"
                                                id="importFile"
                                                type="file"
                                                className={styles.fileInput}
                                                ref={hiddenMediaDeInput}
                                                onChange={(event) => {
                                                    props.setFieldValue(
                                                        file_de.name,
                                                        event.currentTarget.files[
                                                            globalConstants.KEYS.FIRST_FILE_INDEX
                                                        ],
                                                    );
                                                }}
                                            />
                                            <Button
                                                className={styles.uploadImg}
                                                onClick={() => handleUploadClick(file_de.name)}
                                                startIcon={<CloudUploadIcon />}
                                            >
                                                {de.upload_step_picture_video}
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <div className={styles.bottomNavigation}>
                                    <Button
                                        className={clsx(styles.bottomNavigationButton, 'mr2', 'mt4')}
                                        onClick={handleNext}
                                    >
                                        {en.next}
                                    </Button>
                                    <Button
                                        type="submit"
                                        className={clsx(styles.bottomNavigationButton, 'mr2', 'mt4')}
                                        disabled={props.isSubmitting || !(props.isValid && props.dirty)}
                                    >
                                        {en.save_button_label}
                                    </Button>
                                    <Button
                                        className={clsx(styles.bottomNavigationButton, 'mr2', 'mt4')}
                                        onClick={() => prevStep(tutorialData)}
                                    >
                                        {en.back}
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

const mapStateToProps = (state: AppState) => ({
    progressUpload: state.tutorials.progressUpload,
    loading: state.tutorials.loading,
});

const mapDispatchToProps = {
    getTutorialSteps: tutorialActions.getTutorialSteps,
    addTutorialStep: tutorialActions.addTutorialStep,
    updateTutorialStep: tutorialActions.updateTutorialStep,
    deleteTutorialStep: tutorialActions.deleteTutorialStep,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(TutorialStep);

import en from '../../../../../../assets/language/en.json';
import de from '../../../../../../assets/language/de.json';
import { uniqueId } from 'lodash';

const formModel = {
    formId: uniqueId('form-'),
    formField: {
        titleEn: {
            name: 'titleEn',
            label: en.title_label,
            requiredErrorMsg: en.title_error_msg,
        },
        titleDe: {
            name: 'titleDe',
            label: de.title_label,
            requiredErrorMsg: de.title_error_msg,
        },
        picture: {
            name: 'picture',
            label: en.picture_label,
        },
        milieuPicture: {
            name: 'milieuPicture',
            label: en.milieu_picture_label,
        },
        randomProduct: {
            name: 'randomProduct',
            label: en.random_product_label,
        },
        shopifyDeAtProductId: {
            name: 'shopifyDeAtProductId',
            label: 'Shopify DE/AT Product ID',
        },
        shopifyUsProductId: {
            name: 'shopifyUsProductId',
            label: 'Shopify US Product ID',
        },
        shopifyDeAtVariantId: {
            name: 'shopifyDeAtVariantId',
            label: 'Shopify DE/AT Variant ID',
        },
        shopifyUsVariantId: {
            name: 'shopifyUsVariantId',
            label: 'Shopify US Variant ID',
        },
        categories: {
            name: 'categories',
            label: en.product_categories,
        },
        pictureAltTextEn: {
            name: 'pictureAltTextEn',
            label: 'Picture alt text en',
        },
        pictureAltTextDe: {
            name: 'pictureAltTextDe',
            label: 'Picture alt text de',
        },
        milieuPictureAltTextEn: {
            name: 'milieuPictureAltTextEn',
            label: 'Milieu picture alt text en',
        },
        milieuPictureAltTextDe: {
            name: 'milieuPictureAltTextDe',
            label: 'Milieu picture alt text de',
        },
    },
};

const formModels = {
    productFormModel: formModel,
};

export default formModels;

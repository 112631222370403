import * as Yup from 'yup';
import formModels from './form.model';
import { ObjectSchema } from 'yup';
import { IFilterForm } from '../../../filter.interfaces';

const {
    formField: { name_en, name_de },
} = formModels.filterFormModel;

const filterValidationSchema: ObjectSchema<IFilterForm> = Yup.object()
    .defined()
    .shape({
        name_en: Yup.string().required(`${name_en.requiredErrorMsg}`).defined(),
        name_de: Yup.string().required(`${name_de.requiredErrorMsg}`).defined(),
        picture: Yup.mixed(),
        pictureAltTextEn: Yup.string(),
        pictureAltTextDe: Yup.string(),
    });

const validationSchemas = {
    filterValidationSchema,
};

export default validationSchemas;

import { ITroubleshoot, ITroubleshootPrimaryStep, ITroubleshootStep } from '../../../troubleshoot.interfaces';

const troubleshoot: ITroubleshoot = {
    id: null,
    internalName: '',
    thumbnailDe: null,
    thumbnailEn: null,
    thumbnailAltTextDe: '',
    thumbnailAltTextEn: '',
    videoDe: null,
    videoEn: null,
};

const troubleshootPrimaryStep: ITroubleshootPrimaryStep = {
    id: null,
    thumbnailDe: null,
    thumbnailEn: null,
    thumbnailAltTextDe: '',
    thumbnailAltTextEn: '',
    titleDe: '',
    titleEn: '',
};

const basicTroubleshootStep: ITroubleshootStep = {
    id: null,
    defaultDescription: {
        enEnTranslation: '',
        deDeTranslation: '',
    },
};

const platformSpecificTroubleshootStep: ITroubleshootStep = {
    id: null,
    oldFirmwareIOS: null,
    oldFirmwareAndroid: null,
    semanticFirmwareIOS: null,
    semanticFirmwareAndroid: null,
    isResolvingStep: false,
};

export const formInitialValues = {
    troubleshoot,
    basicTroubleshootStep,
    troubleshootPrimaryStep,
    platformSpecificTroubleshootStep,
};

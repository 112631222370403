import en from '../../../../../../assets/language/en.json';
import de from '../../../../../../assets/language/de.json';

const formModel = {
    formId: 'addOrEditTutorialForm',
    formField: {
        title_en: {
            name: 'title_en',
            label: en.title_label,
            requiredErrorMsg: en.title_error_msg,
        },
        title_de: {
            name: 'title_de',
            label: de.title_label,
            requiredErrorMsg: de.title_error_msg,
        },
        header_picture: {
            name: 'header_picture',
            label: en.header_picture,
        },
        headerPictureAltTextEn: {
            name: 'headerPictureAltTextEn',
            label: 'Header picture alt text en',
        },
        headerPictureAltTextDe: {
            name: 'headerPictureAltTextDe',
            label: 'Header picture alt text de',
        },
    },
};

const tutorialStepFormModel = {
    formId: 'addOrEditTutorialStepForm',
    formField: {
        text_en: {
            name: 'text_en',
            label: en.step_description_label,
            requiredErrorMsg: en.tutorial_step_description_error_msg,
        },
        text_de: {
            name: 'text_de',
            label: de.step_description_label,
            requiredErrorMsg: de.tutorial_step_description_error_msg,
        },
        file_en: {
            name: 'file_en',
            label: en.file_label,
            requiredErrorMsg: en.file_required_error_msg,
        },
        file_de: {
            name: 'file_de',
            label: de.file_label,
            requiredErrorMsg: de.file_required_error_msg,
        },
        fileAltTextEn: {
            name: 'fileAltTextEn',
            label: 'File alt text en',
        },
        fileAltTextDe: {
            name: 'fileAltTextDe',
            label: 'File alt text de',
        },
    },
};

const formModels = {
    tutorialFormModel: formModel,
    tutorialStepFormModel,
};

export default formModels;

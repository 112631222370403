import {
    Button,
    Card,
    CardActionArea,
    CardActions,
    Checkbox,
    Dialog,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputAdornment,
    InputLabel,
    Link,
    List,
    ListItem,
    ListSubheader,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { ToggleButtonGroup } from '@material-ui/lab';
import clsx from 'clsx';
import { Form, Formik, FormikProps } from 'formik';
import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import en from '../../../../../assets/language/en.json';
import ColorInputField from '../../../../shared/components/generics/colorInputField/ColorInputField';
import CustomDatePicker from '../../../../shared/components/generics/datePicker/CustomDatePicker';
import LanguageFlag from '../../../../shared/components/generics/languageFlag/LanguageFlag';
import { globalConstants } from '../../../../shared/constants/global.constants';
import { DispatchPropsReturnType } from '../../../../shared/helpers/generics';
import { AppState } from '../../../../shared/store';
import ImportFile from '../../../import/pages/import.file';
import { IProduct } from '../../../upselling/upselling.interfaces';
import { promotionCampaignActions } from '../../promotion.campaign.actions';
import { targetUserLabels } from '../../promotion.campaign.constants';
import { promotionCampaignEndpoints } from '../../promotion.campaign.endpoints';
import PropTypes from 'prop-types';
import {
    CampaignDisplay,
    ContentOrientation,
    DiscountType,
    IAddPromotionCampaignProps,
    IPromotionCampaignCouponResult,
    IPromotionCampaignData,
    ISegmentAudience,
    MarketingRegions,
    TargetUser,
} from '../../promotion.campaign.interfaces';
import { formInitialValues } from './formModel/form.initial.values';
import formModels from './formModel/promotion.campaign.form.model';
import validationSchemas from './formModel/validation.schema';
import styles from './index.module.scss';
import { useIdParam } from '../../../../shared/helpers/custom.hooks';
import { zodUtils } from '../../../../shared/helpers/zodUtils';
import { StyledToggleButton } from '../../../../shared/components/generics/styledComponents/StyledComponents';
import SearchIcon from '@material-ui/icons/Search';
import AltText from '../../../../shared/components/generics/AltText/AltText';

type PromotionCampaign = IAddPromotionCampaignProps &
    typeof formModels.promotionCampaignFormModel &
    DispatchPropsReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps>;

const containsText = (text: string, searchText: string): boolean =>
    text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

const filterSegmentAudiences = (searchText: string, audiences: ISegmentAudience[]): ISegmentAudience[] => {
    return audiences.filter((audience) => containsText(audience.name, searchText));
};

const AddOrEditPromotionCampaign = (props: PropsWithChildren<PromotionCampaign>): JSX.Element => {
    const id = useIdParam();

    const [updatingPromotionCampaign, setUpdatingPromotionCampaign] = useState<any>(null);
    const [previewLanguage, setPreviewLanguage] = useState<'En' | 'De'>('En');
    const [isCouponActive, setIsCouponActive] = useState<boolean>(false);
    const [selectedRegions, setSelectedRegions] = useState<string[]>([]);
    const [segmentAudiences, setSegmentAudiences] = useState<ISegmentAudience[]>([]);
    const [searchText, setSearchText] = useState('');
    const foundSegmentAudiences = useMemo(
        () => filterSegmentAudiences(searchText, segmentAudiences),
        [searchText, segmentAudiences],
    );

    const [startDate, setStartDate] = useState<Date>(new Date());
    const [endDate, setEndDate] = useState<Date>(new Date());

    const pictureEnInput = React.useRef(null);
    const videoEnInput = React.useRef(null);
    const pictureDeInput = React.useRef(null);
    const videoDeInput = React.useRef(null);

    const formInitials = updatingPromotionCampaign ?? formInitialValues.promotionCampaign;
    const form = formModels.promotionCampaignFormModel.formField;

    useEffect(() => {
        if (id) {
            props.getPromotionCampaign(id).then((promotionCampaign) => {
                const startDate = new Date(promotionCampaign.startDate);
                const endDate = new Date(promotionCampaign.endDate);

                setStartDate(new Date(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate()));
                setEndDate(new Date(endDate.getUTCFullYear(), endDate.getUTCMonth(), endDate.getUTCDate()));
                setUpdatingPromotionCampaign(promotionCampaign);
                if (promotionCampaign.coupon) {
                    setIsCouponActive(true);
                }
                setSelectedRegions(promotionCampaign.regions.map((region) => region.regionName));
            });
        }
    }, []);

    useEffect(() => {
        props.getSegmentAudiences().then((segmentAudiences) => {
            setSegmentAudiences(segmentAudiences);
        });
    }, []);

    const handleSubmit = async (values: IPromotionCampaignData): Promise<void> => {
        await (updatingPromotionCampaign ? handleUpdate(values) : handleInsert(values));
        props.history.push(promotionCampaignEndpoints.PROMOTION_CAMPAIGN_ROUTES.PROMOTION_CAMPAIGN);
    };

    const handleInsert = async (values: IPromotionCampaignData): Promise<void> => {
        await props.addPromotionCampaign(values);
    };

    const handleUpdate = async (values: IPromotionCampaignData): Promise<void> => {
        await props.updatePromotionCampaign(values, id);
    };

    return (
        <Paper className={clsx(styles.productPaper, 'mt16')} variant={'outlined'}>
            <div className={clsx(styles.product, 'p4')}>
                <Formik
                    initialValues={formInitials}
                    onSubmit={(values: IPromotionCampaignData, { setSubmitting }) => {
                        handleSubmit(values);
                        setSubmitting(false);
                    }}
                    validate={zodUtils.zodFormikValidator(validationSchemas.zodValidationSchema)}
                    enableReinitialize={true}
                >
                    {(formProps: FormikProps<IPromotionCampaignData>) => (
                        <Form className={styles.addProductForm}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h3" gutterBottom className={clsx(styles.typography, 'mt8')}>
                                        {en.campaign_label}
                                    </Typography>
                                </Grid>
                                {/* Left grid container for main input form */}
                                <Grid item xs={7} container spacing={2}>
                                    <Grid item xs={12}>
                                        <p className={styles.formHeader}>Campaign settings</p>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                            <TextField
                                                className={styles.formInput}
                                                label={form.campaignName.label}
                                                name={form.campaignName.name}
                                                type="text"
                                                required
                                                onChange={formProps.handleChange}
                                                onBlur={formProps.handleBlur}
                                                value={formProps.values.campaignName}
                                                variant="outlined"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <LanguageFlag country="us" />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                {formProps.errors.campaignName && formProps.touched.campaignName
                                                    ? formProps.errors.campaignName
                                                    : ''}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                            <TextField
                                                className={styles.formInput}
                                                label={form.regions.label}
                                                name={form.regions.name}
                                                type="text"
                                                required
                                                select
                                                SelectProps={{
                                                    multiple: true,
                                                }}
                                                onChange={(event) => {
                                                    const newSelectedRegions = event.target.value as any;
                                                    setSelectedRegions(newSelectedRegions);
                                                    const oldRegionData = [...formProps.values.regions];
                                                    const newRegionData = oldRegionData.filter((region) => {
                                                        return newSelectedRegions.includes(region.regionName);
                                                    });
                                                    formProps.setFieldValue(form.regions.name, newRegionData);
                                                }}
                                                value={selectedRegions}
                                                variant="outlined"
                                            >
                                                <MenuItem disabled value="">
                                                    <em>{form.regions.label}</em>
                                                </MenuItem>
                                                {Object.keys(MarketingRegions).map((key) => (
                                                    <MenuItem key={key} value={key}>
                                                        {MarketingRegions[key]}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                            <CustomDatePicker
                                                label={form.startDate.label}
                                                name={form.startDate.name}
                                                value={startDate}
                                                onChange={(newDate) => {
                                                    setStartDate(newDate);

                                                    const newDateUtc = Date.UTC(
                                                        newDate.getFullYear(),
                                                        newDate.getMonth(),
                                                        newDate.getDate(),
                                                        0,
                                                        0,
                                                        0,
                                                    );
                                                    formProps.setFieldValue(
                                                        form.startDate.name,
                                                        new Date(newDateUtc).toISOString(),
                                                    );
                                                }}
                                                placeholder="DD/MM/YY"
                                                dateFormat="dd/MM/yy"
                                            />
                                            <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                {formProps.errors.startDate && formProps.touched.startDate
                                                    ? (formProps.errors.startDate as React.ReactNode)
                                                    : ''}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                            <CustomDatePicker
                                                label={form.endDate.label}
                                                name={form.endDate.name}
                                                value={endDate}
                                                onChange={(newDate) => {
                                                    setEndDate(newDate);

                                                    const newDateUtc = Date.UTC(
                                                        newDate.getFullYear(),
                                                        newDate.getMonth(),
                                                        newDate.getDate(),
                                                        23,
                                                        59,
                                                        59,
                                                    );
                                                    formProps.setFieldValue(
                                                        form.endDate.name,
                                                        new Date(newDateUtc).toISOString(),
                                                    );
                                                }}
                                                placeholder="DD/MM/YY"
                                                dateFormat="dd/MM/yy"
                                            />
                                            <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                {formProps.errors.endDate && formProps.touched.endDate
                                                    ? (formProps.errors.endDate as React.ReactNode)
                                                    : ''}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    {selectedRegions.length > 0 ? (
                                        <Grid item container xs={12} spacing={2}>
                                            {selectedRegions.map((region) => (
                                                <Grid key={region} item xs={4}>
                                                    <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                                        <TextField
                                                            className={styles.formInput}
                                                            name={region}
                                                            // name={`regions.${region}.shopUrl`}
                                                            label={`Button URL (${MarketingRegions[region]})`}
                                                            type="text"
                                                            required
                                                            onChange={(event) => {
                                                                const existingIndex =
                                                                    formProps.values.regions.findIndex(
                                                                        (r) => r.regionName === region,
                                                                    );

                                                                if (existingIndex === -1) {
                                                                    formProps.setFieldValue(
                                                                        form.regions.name,
                                                                        formProps.values.regions.concat({
                                                                            regionName: region as any,
                                                                            shopUrl: event.target.value,
                                                                        }),
                                                                    );
                                                                } else {
                                                                    const newRegions = [...formProps.values.regions];
                                                                    newRegions[existingIndex].shopUrl =
                                                                        event.target.value;
                                                                    formProps.setFieldValue(
                                                                        form.regions.name,
                                                                        newRegions,
                                                                    );
                                                                }
                                                            }}
                                                            onBlur={formProps.handleBlur}
                                                            value={
                                                                formProps.values.regions.find(
                                                                    (r) => r.regionName === region,
                                                                )?.shopUrl
                                                            }
                                                            variant="outlined"
                                                        />
                                                        {/* <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                            {formProps.errors.redirectUrlEn &&
                                                            formProps.touched.redirectUrlEn
                                                                ? formProps.errors.redirectUrlEn
                                                                : ''}
                                                        </FormHelperText> */}
                                                    </FormControl>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    ) : null}
                                    <Grid item xs={12}>
                                        <p className={styles.formHeader}>Banner content</p>
                                    </Grid>
                                    <Grid container item xs={12} spacing={2}>
                                        <Grid item xs={5}>
                                            <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                                <TextField
                                                    className={styles.formInput}
                                                    label={form.titleEn.label}
                                                    name={form.titleEn.name}
                                                    type="text"
                                                    multiline
                                                    required
                                                    onChange={(e) => {
                                                        e.target.value = e.target.value?.toUpperCase();
                                                        return formProps.handleChange(e);
                                                    }}
                                                    onBlur={formProps.handleBlur}
                                                    value={formProps.values.titleEn}
                                                    variant="outlined"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <LanguageFlag country="us" />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                    {formProps.errors.titleEn && formProps.touched.titleEn
                                                        ? formProps.errors.titleEn
                                                        : ''}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                                <TextField
                                                    className={styles.formInput}
                                                    label={form.titleDe.label}
                                                    name={form.titleDe.name}
                                                    type="text"
                                                    multiline
                                                    required
                                                    onChange={(e) => {
                                                        e.target.value = e.target.value?.toUpperCase();
                                                        return formProps.handleChange(e);
                                                    }}
                                                    onBlur={formProps.handleBlur}
                                                    value={formProps.values.titleDe}
                                                    variant="outlined"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <LanguageFlag country="de" />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                    {formProps.errors.titleDe && formProps.touched.titleDe
                                                        ? formProps.errors.titleDe
                                                        : ''}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                                <ColorInputField
                                                    fieldVariant="onlyCircles"
                                                    style={{
                                                        height: '56px',
                                                    }}
                                                    colors={[
                                                        { colorHex: '#E9E9E9', name: 'White' },
                                                        { colorHex: '#F41717', name: 'Red' },
                                                        { colorHex: '#000000', name: 'Black' },
                                                    ]}
                                                    handleColorChange={(color) =>
                                                        formProps.setFieldValue(form.textColorHex.name, color)
                                                    }
                                                    className={styles.formInput}
                                                    label={form.textColorHex.label}
                                                    name={form.textColorHex.name}
                                                    type="text"
                                                    required
                                                    onChange={formProps.handleChange}
                                                    onBlur={formProps.handleBlur}
                                                    value={formProps.values.textColorHex}
                                                    variant="outlined"
                                                />
                                                <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                    {formProps.errors.textColorHex && formProps.touched.textColorHex
                                                        ? formProps.errors.textColorHex
                                                        : ''}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} spacing={2}>
                                        <Grid item xs={5}>
                                            <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                                <TextField
                                                    className={styles.formInput}
                                                    label={form.descriptionEn.label}
                                                    name={form.descriptionEn.name}
                                                    type="text"
                                                    multiline
                                                    required
                                                    onChange={formProps.handleChange}
                                                    onBlur={formProps.handleBlur}
                                                    value={formProps.values.descriptionEn}
                                                    variant="outlined"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <LanguageFlag country="us" />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                    {formProps.errors.descriptionEn && formProps.touched.descriptionEn
                                                        ? formProps.errors.descriptionEn
                                                        : ''}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                                <TextField
                                                    className={styles.formInput}
                                                    label={form.descriptionDe.label}
                                                    name={form.descriptionDe.name}
                                                    type="text"
                                                    multiline
                                                    required
                                                    onChange={formProps.handleChange}
                                                    onBlur={formProps.handleBlur}
                                                    value={formProps.values.descriptionDe}
                                                    variant="outlined"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <LanguageFlag country="de" />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                    {formProps.errors.descriptionDe && formProps.touched.descriptionDe
                                                        ? formProps.errors.descriptionDe
                                                        : ''}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                                <ColorInputField
                                                    fieldVariant="onlyCircles"
                                                    style={{
                                                        height: '56px',
                                                    }}
                                                    colors={[
                                                        { colorHex: '#E9E9E9', name: 'White' },
                                                        { colorHex: '#F41717', name: 'Red' },
                                                        { colorHex: '#000000', name: 'Black' },
                                                    ]}
                                                    handleColorChange={(color) =>
                                                        formProps.setFieldValue(form.descriptionColorHex.name, color)
                                                    }
                                                    className={styles.formInput}
                                                    label={form.descriptionColorHex.label}
                                                    name={form.descriptionColorHex.name}
                                                    type="text"
                                                    required
                                                    onChange={formProps.handleChange}
                                                    onBlur={formProps.handleBlur}
                                                    value={formProps.values.descriptionColorHex}
                                                    variant="outlined"
                                                />
                                                <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                    {formProps.errors.descriptionColorHex &&
                                                    formProps.touched.descriptionColorHex
                                                        ? formProps.errors.descriptionColorHex
                                                        : ''}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} spacing={2}>
                                        <Grid item xs={5}>
                                            <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                                <TextField
                                                    className={styles.formInput}
                                                    label={form.buttonTitleEn.label}
                                                    name={form.buttonTitleEn.name}
                                                    type="text"
                                                    required
                                                    onChange={formProps.handleChange}
                                                    onBlur={formProps.handleBlur}
                                                    value={formProps.values.buttonTitleEn}
                                                    variant="outlined"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <LanguageFlag country="us" />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                    {formProps.errors.buttonTitleEn && formProps.touched.buttonTitleEn
                                                        ? formProps.errors.buttonTitleEn
                                                        : ''}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                                <TextField
                                                    className={styles.formInput}
                                                    label={form.buttonTitleDe.label}
                                                    name={form.buttonTitleDe.name}
                                                    type="text"
                                                    required
                                                    onChange={formProps.handleChange}
                                                    onBlur={formProps.handleBlur}
                                                    value={formProps.values.buttonTitleDe}
                                                    variant="outlined"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <LanguageFlag country="de" />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                    {formProps.errors.buttonTitleDe && formProps.touched.buttonTitleDe
                                                        ? formProps.errors.buttonTitleDe
                                                        : ''}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                                <ColorInputField
                                                    fieldVariant="onlyCircles"
                                                    style={{
                                                        height: '56px',
                                                    }}
                                                    colors={[
                                                        { colorHex: '#E9E9E9', name: 'White' },
                                                        { colorHex: '#F41717', name: 'Red' },
                                                        { colorHex: '#000000', name: 'Black' },
                                                    ]}
                                                    handleColorChange={(color) =>
                                                        formProps.setFieldValue(form.buttonColorHex.name, color)
                                                    }
                                                    className={styles.formInput}
                                                    label={form.buttonColorHex.label}
                                                    name={form.buttonColorHex.name}
                                                    type="text"
                                                    required
                                                    onChange={formProps.handleChange}
                                                    onBlur={formProps.handleBlur}
                                                    value={formProps.values.buttonColorHex}
                                                    variant="outlined"
                                                />
                                                <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                    {formProps.errors.buttonColorHex && formProps.touched.buttonColorHex
                                                        ? formProps.errors.buttonColorHex
                                                        : ''}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                            <ColorInputField
                                                fieldVariant="textfield"
                                                style={{
                                                    height: '56px',
                                                }}
                                                colors={[
                                                    { colorHex: '#E9E9E9', name: 'White' },
                                                    { colorHex: '#F41717', name: 'Red' },
                                                    { colorHex: '#000000', name: 'Black' },
                                                ]}
                                                handleColorChange={(color) =>
                                                    formProps.setFieldValue(form.closeIconColorHex.name, color)
                                                }
                                                className={styles.formInput}
                                                label={form.closeIconColorHex.label}
                                                name={form.closeIconColorHex.name}
                                                type="text"
                                                required
                                                onChange={formProps.handleChange}
                                                onBlur={formProps.handleBlur}
                                                value={formProps.values.closeIconColorHex}
                                                variant="outlined"
                                            />
                                            <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                {formProps.errors.closeIconColorHex &&
                                                formProps.touched.closeIconColorHex
                                                    ? formProps.errors.closeIconColorHex
                                                    : ''}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                            <TextField
                                                className={styles.formInput}
                                                label={form.targetUsers.label}
                                                name={form.targetUsers.name}
                                                type="text"
                                                select
                                                SelectProps={{
                                                    multiple: true,
                                                }}
                                                onChange={formProps.handleChange}
                                                onBlur={formProps.handleBlur}
                                                value={formProps.values.targetUsers}
                                                variant="outlined"
                                            >
                                                <MenuItem disabled value="">
                                                    <em>{form.targetUsers.label}</em>
                                                </MenuItem>
                                                {Object.entries(TargetUser).map(([key, value]) => (
                                                    <MenuItem key={key} value={value}>
                                                        {targetUserLabels[key]}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                {formProps.errors.targetUsers && formProps.touched.targetUsers
                                                    ? formProps.errors.targetUsers
                                                    : ''}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid container item xs={12} spacing={2}>
                                        <Grid item xs={6}>
                                            <FormControl className={clsx(styles.formControl, 'mb4')}>
                                                <SelectSegmentAudiencesMenu
                                                    onClose={() => setSearchText('')}
                                                    onSearchTextChange={setSearchText}
                                                    formProps={formProps}
                                                    form={form}
                                                    segmentAudiences={segmentAudiences}
                                                    foundSegmentAudiences={foundSegmentAudiences}
                                                ></SelectSegmentAudiencesMenu>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <p className={styles.formHeader}>{form.display.label}</p>
                                        <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                            <ToggleButtonGroup
                                                value={formProps.values.display}
                                                exclusive
                                                onChange={(_, value: CampaignDisplay) =>
                                                    formProps.setFieldValue(form.display.name, value)
                                                }
                                                onBlur={formProps.handleBlur}
                                                aria-label="Platform"
                                            >
                                                {Object.keys(CampaignDisplay).map((key) => (
                                                    <StyledToggleButton
                                                        key={key}
                                                        value={CampaignDisplay[key]}
                                                        name={form.display.name}
                                                    >
                                                        {CampaignDisplay[key]}
                                                    </StyledToggleButton>
                                                ))}
                                            </ToggleButtonGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <p className={styles.formHeader}>{form.contentOrientation.label}</p>
                                        <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                            <ToggleButtonGroup
                                                classes={{}}
                                                value={formProps.values.contentOrientation}
                                                exclusive
                                                onChange={(_, value: ContentOrientation) =>
                                                    formProps.setFieldValue(form.contentOrientation.name, value)
                                                }
                                                onBlur={formProps.handleBlur}
                                                aria-label="Platform"
                                            >
                                                {Object.keys(ContentOrientation).map((key) => (
                                                    <StyledToggleButton
                                                        key={key}
                                                        value={ContentOrientation[key]}
                                                        name={form.contentOrientation.name}
                                                    >
                                                        {ContentOrientation[key]}
                                                    </StyledToggleButton>
                                                ))}
                                            </ToggleButtonGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                            <FormControlLabel
                                                className={clsx('mb8')}
                                                control={
                                                    <Checkbox
                                                        name={'couponActive'}
                                                        onChange={(event) => {
                                                            const checked = event.target.checked;
                                                            if (checked) {
                                                                formProps.setFieldValue(
                                                                    form.coupon.name,
                                                                    formInitialValues.promotionCampaignCoupon,
                                                                );
                                                            } else {
                                                                formProps.setFieldValue(form.coupon.name, null);
                                                            }
                                                            setIsCouponActive(checked);
                                                        }}
                                                        checked={isCouponActive}
                                                    />
                                                }
                                                label={'Coupon'}
                                            />
                                        </FormControl>
                                    </Grid>
                                    {isCouponActive ? (
                                        <CouponSection formProps={formProps} selectedRegions={selectedRegions} />
                                    ) : null}
                                </Grid>
                                {/* Right grid container for media upload */}
                                <Grid item xs={5} container spacing={2}>
                                    <Grid item xs={6}>
                                        <FormControl className={clsx(styles.formControl, 'mb4')}>
                                            <Card>
                                                <CardActionArea className={styles.actionArea}>
                                                    <ImportFile
                                                        file={formProps.values.pictureEn}
                                                        title={form.pictureEn.label}
                                                        style={styles.imgCard}
                                                        remove={() =>
                                                            formProps.setFieldValue(form.pictureEn.name, null)
                                                        }
                                                    />
                                                </CardActionArea>
                                                <CardActions>
                                                    <input
                                                        accept="file/"
                                                        id={form.pictureEn.name}
                                                        type="file"
                                                        name={form.pictureEn.name}
                                                        className={styles.fileInput}
                                                        ref={pictureEnInput}
                                                        onChange={(event) => {
                                                            formProps.setFieldValue(
                                                                form.pictureEn.name,
                                                                event.currentTarget.files[
                                                                    globalConstants.KEYS.FIRST_FILE_INDEX
                                                                ],
                                                            );
                                                        }}
                                                    />
                                                    <Button
                                                        className={styles.uploadImg}
                                                        onClick={() => pictureEnInput.current.click()}
                                                        startIcon={<CloudUploadIcon />}
                                                    >
                                                        {en.upload_image}
                                                    </Button>
                                                </CardActions>
                                            </Card>
                                        </FormControl>
                                        <FormControl className={clsx(styles.formControl, 'mb4')}>
                                            <Card>
                                                <CardActionArea className={styles.actionArea}>
                                                    <ImportFile
                                                        file={formProps.values.videoEn}
                                                        title={form.videoEn.label}
                                                        style={styles.imgCard}
                                                        remove={() => formProps.setFieldValue(form.videoEn.name, null)}
                                                    />
                                                </CardActionArea>
                                                <CardActions>
                                                    <input
                                                        accept="file/"
                                                        id={form.videoEn.name}
                                                        type="file"
                                                        name={form.videoEn.name}
                                                        className={styles.fileInput}
                                                        ref={videoEnInput}
                                                        onChange={(event) => {
                                                            formProps.setFieldValue(
                                                                form.videoEn.name,
                                                                event.currentTarget.files[
                                                                    globalConstants.KEYS.FIRST_FILE_INDEX
                                                                ],
                                                            );
                                                        }}
                                                    />
                                                    <Button
                                                        className={styles.uploadImg}
                                                        onClick={() => videoEnInput.current.click()}
                                                        startIcon={<CloudUploadIcon />}
                                                    >
                                                        {en.upload_video}
                                                    </Button>
                                                </CardActions>
                                            </Card>
                                            <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                {formProps.errors.videoEn ? formProps.errors.videoEn : ''}
                                            </FormHelperText>
                                        </FormControl>
                                        <AltText
                                            {...formProps}
                                            altTextDeFieldMeta={form.pictureAltTextDe}
                                            altTextEnFieldMeta={form.pictureAltTextEn}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl className={clsx(styles.formControl, 'mb4')}>
                                            <Card>
                                                <CardActionArea className={styles.actionArea}>
                                                    <ImportFile
                                                        file={formProps.values.pictureDe}
                                                        title={form.pictureDe.label}
                                                        style={styles.imgCard}
                                                        remove={() =>
                                                            formProps.setFieldValue(form.pictureDe.name, null)
                                                        }
                                                    />
                                                </CardActionArea>
                                                <CardActions>
                                                    <input
                                                        accept="file/"
                                                        id={form.pictureDe.name}
                                                        type="file"
                                                        name={form.pictureDe.name}
                                                        className={styles.fileInput}
                                                        ref={pictureDeInput}
                                                        onChange={(event) => {
                                                            formProps.setFieldValue(
                                                                form.pictureDe.name,
                                                                event.currentTarget.files[
                                                                    globalConstants.KEYS.FIRST_FILE_INDEX
                                                                ],
                                                            );
                                                        }}
                                                    />
                                                    <Button
                                                        className={styles.uploadImg}
                                                        onClick={() => pictureDeInput.current.click()}
                                                        startIcon={<CloudUploadIcon />}
                                                    >
                                                        {en.upload_image}
                                                    </Button>
                                                </CardActions>
                                            </Card>
                                        </FormControl>
                                        <FormControl className={clsx(styles.formControl, 'mb4')}>
                                            <Card>
                                                <CardActionArea className={styles.actionArea}>
                                                    <ImportFile
                                                        file={formProps.values.videoDe}
                                                        title={form.videoDe.label}
                                                        style={styles.imgCard}
                                                        remove={() => formProps.setFieldValue(form.videoDe.name, null)}
                                                    />
                                                </CardActionArea>
                                                <CardActions>
                                                    <input
                                                        accept="file/"
                                                        id={form.videoDe.name}
                                                        type="file"
                                                        name={form.videoDe.name}
                                                        className={styles.fileInput}
                                                        ref={videoDeInput}
                                                        onChange={(event) => {
                                                            formProps.setFieldValue(
                                                                form.videoDe.name,
                                                                event.currentTarget.files[
                                                                    globalConstants.KEYS.FIRST_FILE_INDEX
                                                                ],
                                                            );
                                                        }}
                                                    />
                                                    <Button
                                                        className={styles.uploadImg}
                                                        onClick={() => videoDeInput.current.click()}
                                                        startIcon={<CloudUploadIcon />}
                                                    >
                                                        {en.upload_video}
                                                    </Button>
                                                </CardActions>
                                            </Card>
                                            <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                                                {formProps.errors.videoDe ? formProps.errors.videoDe : ''}
                                            </FormHelperText>
                                        </FormControl>
                                        <AltText
                                            {...formProps}
                                            altTextDeFieldMeta={form.videoAltTextDe}
                                            altTextEnFieldMeta={form.videoAltTextEn}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <p className={styles.formHeader}>Preview language</p>
                                        <FormControl className={clsx(styles.formControl, 'mb4')} required>
                                            <ToggleButtonGroup
                                                value={previewLanguage}
                                                exclusive
                                                onChange={(_, value) => value && setPreviewLanguage(value)}
                                                aria-label="Platform"
                                            >
                                                <StyledToggleButton key={'En'} value={'En'} name={'English'}>
                                                    English
                                                </StyledToggleButton>
                                                <StyledToggleButton key={'De'} value={'De'} name={'German'}>
                                                    German
                                                </StyledToggleButton>
                                            </ToggleButtonGroup>
                                        </FormControl>
                                        {previewLanguage ? renderCampaignPreview(formProps, previewLanguage) : null}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={clsx(styles.bottomNavigation, 'mt12')}>
                                        {(() => {
                                            const errorKeys = Object.keys(formProps.errors);
                                            if (errorKeys?.length > 0 && errorKeys?.length <= 3) {
                                                return (
                                                    <FormHelperText className={clsx(styles.error_span, 'mt2', 'mr4')}>
                                                        {errorKeys.map((key) => {
                                                            return (
                                                                <p
                                                                    key={form[`${key}.label`] || key}
                                                                >{`${key}: ${formProps.errors[key]}`}</p>
                                                            );
                                                        })}
                                                    </FormHelperText>
                                                );
                                            }
                                            return null;
                                        })()}
                                        <Button
                                            type="submit"
                                            className={clsx(styles.saveProduct, 'mr2', 'mt4')}
                                            disabled={formProps.isSubmitting || !formProps.isValid}
                                        >
                                            {en.save_button_label}
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </div>
        </Paper>
    );
};

const mapStateToProps = (state: AppState) => ({
    promotionCampaign: state.promotionCampaigns.promotionCampaign,
    loading: state.promotionCampaigns.loading,
});

const mapDispatchToProps = {
    getPromotionCampaign: promotionCampaignActions.getPromotionCampaign,
    addPromotionCampaign: promotionCampaignActions.addPromotionCampaign,
    updatePromotionCampaign: promotionCampaignActions.updatePromotionCampaign,
    getSegmentAudiences: promotionCampaignActions.getSegmentAudiences,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(AddOrEditPromotionCampaign);

type CouponSectionProps = {
    formProps: FormikProps<IPromotionCampaignData>;
    selectedRegions: string[];
} & DispatchPropsReturnType<typeof mapDispatchToCouponProps>;

const mapDispatchToCouponProps = {
    getAvailableCouponProducts: promotionCampaignActions.getAvailableCouponProducts,
    testPromotionCampaignCoupon: promotionCampaignActions.testPromotionCampaignCoupon,
};

const CouponSection = connect(
    null,
    mapDispatchToCouponProps,
)((props: CouponSectionProps) => {
    const { formProps, selectedRegions } = props;

    const [availableProducts, setAvailableProducts] = useState<IProduct[]>([]);
    const [couponResults, setCouponResults] = useState<IPromotionCampaignCouponResult[]>(null);

    useEffect(() => {
        selectedRegions.length > 0 &&
            props.getAvailableCouponProducts(selectedRegions).then((products) => {
                setAvailableProducts(products);
            });
    }, [selectedRegions]);

    const form = formModels.promotionCampaignFormModel.formField;

    return (
        <>
            <CouponTestLinksDialog couponResults={couponResults} onClose={() => setCouponResults(null)} />
            <Grid item xs={4} className={clsx('p-0')}></Grid>
            <Grid item xs={4} className={clsx('p-0')}>
                <p className={styles.formHeader}>{form.coupon.discount.type.label}</p>
            </Grid>
            <Grid item xs={4} className={clsx('p-0')}></Grid>
            <Grid item xs={4}>
                <FormControl className={clsx(styles.formControl, 'mb4')} required>
                    <TextField
                        className={styles.formInput}
                        label={form.coupon.productId.label}
                        name={form.coupon.productId.name}
                        type="text"
                        required
                        select
                        SelectProps={{
                            multiple: false,
                        }}
                        onChange={formProps.handleChange}
                        value={formProps.values.coupon.productId}
                        variant="outlined"
                    >
                        <MenuItem disabled value="">
                            <em>{form.coupon.productId.label}</em>
                        </MenuItem>
                        {availableProducts.map((product) => (
                            <MenuItem key={product.id} value={product.id}>
                                {product.titleEn}
                            </MenuItem>
                        ))}
                    </TextField>
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl className={clsx(styles.formControl, 'mb2')} required>
                    <ToggleButtonGroup
                        className={clsx('mb4')}
                        value={formProps.values.coupon?.discount.type}
                        exclusive
                        onChange={(_, value: DiscountType) =>
                            formProps.setFieldValue(form.coupon.discount.type.name, value)
                        }
                        onBlur={formProps.handleBlur}
                        aria-label="Platform"
                    >
                        {Object.keys(DiscountType).map((key) => (
                            <StyledToggleButton
                                key={key}
                                value={DiscountType[key]}
                                name={form.coupon.discount.type.name}
                            >
                                {DiscountType[key]}
                            </StyledToggleButton>
                        ))}
                    </ToggleButtonGroup>
                    <TextField
                        className={styles.formInput}
                        label={form.coupon.discount.value.label}
                        name={form.coupon.discount.value.name}
                        type="number"
                        required
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                        value={formProps.values.coupon?.discount.value}
                        variant="outlined"
                        helperText="Careful! A percentage discount will apply to the whole quantity of this product, while a fixed discount will apply to only one quantity."
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {formProps.values.coupon?.discount.type === DiscountType.PERCENTAGE ? '%' : '€'}
                                </InputAdornment>
                            ),
                        }}
                    />
                    <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                        {formProps.errors.coupon?.discount?.value && formProps.touched.coupon?.discount?.value
                            ? (formProps.errors.coupon?.discount?.value as React.ReactNode)
                            : ''}
                    </FormHelperText>
                    <Button
                        className={clsx(styles.saveProduct, 'mr2', 'mt4')}
                        disabled={!formProps.isValid}
                        onClick={async () => {
                            const couponResult = await props.testPromotionCampaignCoupon(formProps.values);
                            setCouponResults(couponResult);
                        }}
                    >
                        {'Test Coupons'}
                    </Button>
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl className={clsx(styles.formControl, 'mb4')} required>
                    <CustomDatePicker
                        label={form.coupon.expirationDate.label}
                        name={form.coupon.expirationDate.name}
                        value={formProps.values.coupon?.expirationDate}
                        onChange={(newDate) => {
                            newDate?.setHours(23, 59, 0, 0);
                            formProps.setFieldValue(form.coupon.expirationDate.name, newDate);
                        }}
                        placeholder="DD/MM/YY"
                        dateFormat="dd/MM/yy"
                    />
                    <FormHelperText className={clsx(styles.error_span, 'mt2')}>
                        {formProps.errors.coupon?.expirationDate && formProps.errors.coupon?.expirationDate
                            ? (formProps.errors.coupon?.expirationDate as React.ReactNode)
                            : ''}
                    </FormHelperText>
                </FormControl>
            </Grid>
        </>
    );
});

function renderCampaignPreview(formProps: FormikProps<IPromotionCampaignData>, previewLanguage: string) {
    const imgDiv = document.getElementById('previewImg');
    setPreviewImage(imgDiv, formProps.values[`picture${previewLanguage}`]);
    return (
        <div
            id="previewImg"
            className={styles.preview}
            style={{
                justifyContent: formProps.values.contentOrientation === ContentOrientation.LEFT ? 'left' : 'right',
            }}
        >
            <div
                className={styles.content}
                style={{
                    textAlign: formProps.values.contentOrientation === ContentOrientation.LEFT ? 'left' : 'right',
                    alignItems: formProps.values.contentOrientation === ContentOrientation.LEFT ? 'start' : 'end',
                }}
            >
                <p className={styles.title} style={{ color: formProps.values.textColorHex }}>
                    {formProps.values[`title${previewLanguage}`]}
                </p>
                <div className={styles.description} style={{ color: formProps.values.descriptionColorHex }}>
                    {formProps.values[`description${previewLanguage}`]}
                </div>
                <Button
                    onClick={() => window.open(formProps.values[`redirectUrl${previewLanguage}`], '_blank').focus()}
                    className={clsx(styles.previewButton)}
                    style={{ color: formProps.values.buttonColorHex }}
                >
                    {formProps.values[`buttonTitle${previewLanguage}`]}
                </Button>
            </div>
            <CloseIcon
                style={{
                    color: formProps.values.closeIconColorHex,
                }}
                className={clsx(
                    styles.closeIcon,
                    formProps.values.contentOrientation === ContentOrientation.LEFT
                        ? styles.closeIconRight
                        : styles.closeIconLeft,
                )}
            />
        </div>
    );
}

function setPreviewImage(imgContainer: HTMLElement, imgSrc: string | File) {
    const image = new Image();
    image.onload = () => {
        // image exists and is loaded
        imgContainer.style.backgroundImage = `url("${imgSrc}")`;
    };

    image.onerror = () => {
        try {
            if (imgSrc) {
                const url = URL.createObjectURL(imgSrc as File);
                imgContainer.style.backgroundImage = `url("${url}")`;
            }
        } catch {}
    };

    image.src = imgSrc as string;
}

function CouponTestLinksDialog(props: { onClose: () => void; couponResults?: IPromotionCampaignCouponResult[] }) {
    const { couponResults, onClose } = props;

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog open={!!couponResults} onClose={handleClose}>
            <div className={styles.couponDialog}>
                <DialogTitle className={styles.title}>Generated Test Discounts</DialogTitle>
                <List>
                    {couponResults
                        ? couponResults.map((coupon, idx) => {
                              const shopLink =
                                  coupon.shopifyProduct.onlineStoreUrl || coupon.shopifyProduct.onlineStorePreviewUrl;
                              return (
                                  <React.Fragment key={`${idx}-${coupon}`}>
                                      <ListItem className={styles.item} disableGutters>
                                          <Grid container>
                                              <Grid item xs={4}>
                                                  <Typography variant="body1">{`${coupon.shopifyProduct.title} (Shopify link): `}</Typography>
                                              </Grid>
                                              <Grid item xs={8}>
                                                  <Link href={shopLink} target="_blank">
                                                      {shopLink}
                                                  </Link>
                                              </Grid>
                                          </Grid>
                                      </ListItem>
                                      <ListItem className={styles.item} disableGutters>
                                          <Grid container>
                                              <Grid item xs={4}>
                                                  <Typography variant="body1">{'URL in App (Button URL): '}</Typography>
                                              </Grid>
                                              <Grid item xs={8}>
                                                  <Link href={coupon.discountLinkInApp} target="_blank">
                                                      {coupon.discountLinkInApp}
                                                  </Link>
                                              </Grid>
                                          </Grid>
                                      </ListItem>
                                      {idx < couponResults.length - 1 ? <Divider /> : null}
                                  </React.Fragment>
                              );
                          })
                        : null}
                </List>
            </div>
        </Dialog>
    );
}

const SelectSegmentAudiencesMenu = ({
    form,
    formProps,
    onClose,
    onSearchTextChange,
    foundSegmentAudiences,
    segmentAudiences,
}) => {
    return (
        <>
            <InputLabel id="search-select-audiences-label">Segment audiences</InputLabel>
            <Select
                // Disables auto focus on MenuItems and allows TextField to be in focus
                MenuProps={{ autoFocus: false, variant: 'menu' }}
                labelId="search-select-audiences-label"
                id="search-select-audiences"
                label="Segment audiences"
                name={form.segmentAudiences.name}
                multiple
                onChange={formProps.handleChange}
                onBlur={formProps.handleBlur}
                value={formProps.values.segmentAudiences}
                onClose={onClose}
                // This prevents rendering empty string in Select's value
                // if search text would exclude currently selected option.
                renderValue={() =>
                    segmentAudiences
                        .filter((audience: ISegmentAudience) => formProps.values.segmentAudiences.includes(audience.id))
                        .map((audience: ISegmentAudience) => audience.name)
                        .join(', ')
                }
            >
                <ListSubheader>
                    <TextField
                        size="small"
                        // Autofocus on textfield
                        autoFocus
                        placeholder="Type to search..."
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        onChange={onSearchTextChange}
                        onKeyDown={(e) => {
                            if (e.key !== 'Escape') {
                                // Prevents autoselecting item while typing (default Select behaviour)
                                e.stopPropagation();
                            }
                        }}
                    />
                </ListSubheader>
                {foundSegmentAudiences.map((audience: ISegmentAudience) => (
                    <MenuItem key={audience.id} value={audience.id}>
                        {audience.name}
                    </MenuItem>
                ))}
            </Select>
        </>
    );
};

SelectSegmentAudiencesMenu.propTypes = {
    form: PropTypes.object.isRequired,
    formProps: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onSearchTextChange: PropTypes.func.isRequired,
    foundSegmentAudiences: PropTypes.array.isRequired,
    segmentAudiences: PropTypes.array.isRequired,
};
